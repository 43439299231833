<template>
    <div class="monthly-chart">
        <v-row v-if="loading" style="height: 400px">
            <v-col cols="12" align="center">
                <v-progress-circular indeterminate color="primary" class="mt-15"></v-progress-circular>
            </v-col>
        </v-row>
        <canvas style="height: 300px" v-else vertical id="monthly-chart"> </canvas>
    </div>
</template>
<script>
import Chart from "chart.js";
export default {
    name: "MonthlyChart",
    props: ["chartDatas"],
    data: function () {
        return {
            chartLabels: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                24, 25, 26, 27, 28, 29, 30,
            ],
            loading: true,
            chartOptions: {
                animation: {
                    duration: 4000,
                },
                legend: {
                    display: true,
                    labels: {
                        display: true,
                        fontColor: "black",
                    },
                },
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                fontColor: "black",
                                stepSize: 1000,
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                                color: "#0003",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontColor: "black",
                            },
                            gridLines: {
                                display: true,
                                color: "#0003",
                            },
                        },
                    ],
                },
            },
            dataChart: null,
        };
    },
    created: function () {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    watch: {
        loading: function () {
            let self = this;
            setTimeout(() => {
                self.drawChart();
            }, 500);
        },
        chartDatas: function () {
            if (this.dataChart) {
                this.dataChart.data.datasets[0].data = this.chartDatas;
                this.dataChart.update();
            }
        },
    },
    methods: {
        drawChart: function () {
            console.log("drawing...");
            let ctx = document.getElementById("monthly-chart").getContext("2d");
            this.dataChart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: this.chartLabels,
                    datasets: [
                        {
                            label: "Total (NTD)",
                            data: this.chartDatas,
                            backgroundColor: "#1976D2" + "33",
                            borderColor: "#1976D2",
                            borderWidth: 2,
                            lineTension: 0,
                        },
                    ],
                },
                options: this.chartOptions,
            });
            this.dataChart.draw();
        },
    },
};
</script>
