<template>
    <div class="vouchers-with-list-view">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-icon color="primary">list</v-icon>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                ALL VOUCHERS LIST
                            </p>
                            <v-spacer></v-spacer>
                            <div v-if="currentRole == 0">
                                <v-btn
                                    v-if="!voucherSelecting"
                                    link
                                    depressed
                                    rounded
                                    class="primary lighten-2 mx-2 my-1"
                                    @click="handleSelectVouchers"
                                >
                                    <v-icon>check_box</v-icon>
                                    select vouchers
                                </v-btn>
                                <div v-else>
                                    <v-btn
                                        link
                                        depressed
                                        rounded
                                        class="error lighten-1 mx-2 my-1"
                                        @click="() => (deleteDialog = true)"
                                    >
                                        <v-icon>delete</v-icon>
                                        delete selected
                                    </v-btn>
                                    <v-btn
                                        link
                                        depressed
                                        rounded
                                        class="primary lighten-2 mx-2"
                                        @click="handleUnSelectVouchers"
                                    >
                                        <v-icon>done</v-icon>
                                        done
                                    </v-btn>
                                </div>
                            </div>
                            <v-btn link to="/create-voucher" depressed rounded class="primary">
                                <v-icon>add</v-icon>
                                create new voucher
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-data-table
                                :page="page"
                                :pageCount="numberOfPages"
                                :loading="loading"
                                :headers="headers"
                                :items="vouchers"
                                :items-per-page="itemsPerPage"
                                :server-items-length="totalVouchers"
                                :options.sync="options"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:top>
                                    <form v-on:submit.prevent="handleSearchVoucher">
                                        <v-text-field
                                            color="black"
                                            v-model="searchText"
                                            prepend-icon="search"
                                            placeholder="Search Vouchers"
                                            class="mx-4 black--text text-body-2 px-5"
                                            @click:append="handleSearchVoucher"
                                            clearable
                                            filled
                                            rounded
                                            dense
                                            @click:clear="
                                                readDataWithLimit(
                                                    options.page,
                                                    options.itemsPerPage
                                                )
                                            "
                                        ></v-text-field>
                                    </form>
                                </template>
                                <template v-slot:[`item.select`]="{ item }">
                                    <v-checkbox
                                        @change="(val) => updateSelectedList(item._id, val)"
                                    >
                                    </v-checkbox>
                                </template>
                                <template v-slot:[`item.invoiceId`]="{ item }">
                                    <v-btn
                                        text
                                        small
                                        class="primary--text text-decoration-underline number font-weight-bold no-uppercase"
                                        style="text-transform: none"
                                        @click="() => handleViewVoucherDetail(item)"
                                    >
                                        {{ item.invoiceId }}
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.date`]="{ item }">
                                    <p class="number text--secondary mt-4">{{ item.date }}</p>
                                </template>
                                <template v-slot:[`item.senderName`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.senderName }}</p>
                                </template>
                                <template v-slot:[`item.receiverName`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.receiverName }}</p>
                                </template>
                                <template v-slot:[`item.statusLabel`]="{ item }">
                                    <v-chip
                                        small
                                        dark
                                        :color="item.status == 0 ? 'primary' : 'green'"
                                        >{{ item.statusLabel }}</v-chip
                                    >
                                </template>
                                <template v-slot:[`item.total`]="{ item }">
                                    <td class="number font-weight-medium mt-4">
                                        {{ getTotal(item) }}
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="red">warning</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold red--text">Warning</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    Please confirm that you want to delete selected vouchers.This action can't be
                    revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (deleteDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        x-large
                        :loading="deleteLoading"
                        @click="handleDeleteSelectedVouchers"
                    >
                        <v-icon>delete</v-icon>
                        delete selected vouchers
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { VoucherCollection } from "@/api/voucher_collection";
export default {
    name: "VouchersWithListView",
    data: function () {
        return {
            page: 1,
            numberOfPages: null,
            searchText: "",
            loading: false,
            vouchers: [],
            itemsPerPage: 10,
            totalVouchers: null,
            options: {},
            headers: [
                { text: "Invoice ID", value: "invoiceId" },
                { text: "Date", value: "date" },
                { text: "Sender", value: "senderName" },
                { text: "Reciever", value: "receiverName" },
                { text: "Status", value: "statusLabel" },
                { text: "Total (NTD)", value: "total" },
            ],
            selectedVouchers: [],
            voucherSelecting: false,
            deleteDialog: false,
            deleteLoading: false,
        };
    },
    methods: {
        readDataWithLimit: async function (page, itemsPerPage) {
            //Table is loading
            this.loading = true;
            let voucherCollection = new VoucherCollection();
            try {
                var result = await voucherCollection.getTotalDocuments(this.token);
                this.totalVouchers = result.data.count;
                //calculate skip and limit
                var skip = this.totalVouchers - itemsPerPage * page;
                var limit;
                if (skip < 0) {
                    skip = 0;
                    limit = itemsPerPage + skip;
                } else {
                    limit = itemsPerPage;
                }

                result = await voucherCollection.getDocumentWithRange(skip, limit, this.token);
                this.vouchers = result.data.reverse();
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
            this.loading = false;
        },
        handleSelectVouchers: async function () {
            this.voucherSelecting = true;
            this.headers.splice(0, 0, { name: "Select", value: "select" });
        },
        handleUnSelectVouchers: function () {
            this.voucherSelecting = false;
            this.headers.splice(0, 1);
        },
        handleSearchVoucher: async function () {
            if (this.searchText) {
                this.loading = true;
                try {
                    let voucherCollection = new VoucherCollection();
                    let result = await voucherCollection.searchDocuments(
                        this.searchText,
                        this.token
                    );

                    this.vouchers = result.data;
                    this.totalVouchers = result.data.length;
                } catch (error) {
                    console.log(error.response);
                }
                this.loading = false;
            } else {
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            }
        },
        handleViewVoucherDetail: function (voucher) {
            this.$store.commit("setSelectedVoucher", voucher);
            this.$router.push(`/voucher-detail/${voucher._id}`);
        },
        handleDeleteSelectedVouchers: async function () {
            this.deleteLoading = true;
            try {
                let vouchers = new VoucherCollection();
                await vouchers.deleteWithIds(this.selectedVouchers, this.token);
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
                this.selectedVouchers = [];
                this.voucherSelecting = false;
                this.headers.splice(0, 1);
                this.deleteDialog = false;
            } catch (error) {
                console.log(error);
            }
            this.deleteLoading = false;
        },
        getTotal(voucher) {
            let items = voucher.items;
            let total = 0;
            let tax = 5 / 100;

            items.map((item) => {
                total += this.getPrice(item);
            });
            total = parseInt(total.toFixed(0))

            if (voucher.tax) {
                total = total + total * tax + parseFloat(voucher.carFeeVal);
            } else {
                total = total + parseFloat(voucher.carFeeVal);
            }
            total = parseInt(total.toFixed(0))
            return total
        },
        getPrice(item) {
            return parseFloat(item.weight) * parseFloat(item.pricePerCount);
        },
        updateSelectedList: function (item_id, val) {
            if (val) {
                this.selectedVouchers.push(item_id);
            } else {
                let index = this.selectedVouchers.indexOf(item_id);
                this.selectedVouchers.splice(index, 1);
            }
            console.log(this.selectedVouchers);
        },
    },
    watch: {
        options: function (options) {
            this.readDataWithLimit(options.page, options.itemsPerPage);
        },
    },
    computed: {
        token: function () {
            return this.$store.state.currentUser.token;
        },
        currentRole: function () {
            return this.$store.state.currentUser.role;
        },
    },
};
</script>
