<template>
    <div class="data-report">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-icon color="primary">query_stats</v-icon>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                Monthly Data Report
                            </p>
                            <v-progress-circular
                                v-if="refreshing"
                                size="20"
                                indeterminate
                                color="primary"
                                class="ml-10"
                            ></v-progress-circular>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                rounded
                                class="primary lighten-1"
                                @click="handleExportData"
                            >
                                <v-icon>download</v-icon>
                                export .csv file
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                                    <v-overflow-btn
                                        label="select month"
                                        v-model="selectedMonth"
                                        :items="months"
                                        hint="select month"
                                        color="black"
                                        active-class="black"
                                        item-color="black"
                                        class="text-body-2"
                                    >
                                    </v-overflow-btn>
                                    <v-overflow-btn
                                        label="select year"
                                        v-model="selectedYear"
                                        :items="getYears"
                                        hint="select year"
                                        color="black"
                                        active-class="black"
                                        item-color="black"
                                        class="text-body-2"
                                    >
                                    </v-overflow-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-card class="primary lighten-2">
                                                <v-card-title></v-card-title>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12" align="center">
                                                            <h2 class="number white--text">
                                                                {{ totalVouchers }}
                                                            </h2>
                                                            <p class="body-2 mt-3 white--text">
                                                                <v-icon class="white--text"
                                                                    >description</v-icon
                                                                >Vouchers
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-card class="primary lighten-1">
                                                <v-card-title></v-card-title>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12" align="center">
                                                            <h2 class="number white--text">
                                                                {{ totalPackages }}
                                                            </h2>
                                                            <p class="body-2 mt-3 white--text">
                                                                <v-icon class="white--text"
                                                                    >inventory_2</v-icon
                                                                >Packages
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-card class="primary">
                                                <v-card-title></v-card-title>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12" align="center">
                                                            <h2 class="number white--text">
                                                                {{ totalPrice }}
                                                            </h2>
                                                            <p class="body-2 mt-3 white--text">
                                                                <v-icon class="white--text"
                                                                    >attach_money</v-icon
                                                                >Total(NTD)
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <MonthlyChart :chartDatas="chartDatas" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { VoucherCollection } from "@/api/voucher_collection";
import MonthlyChart from "@/components/chart/MonthlyChart.vue";
import { ExportToCsv } from "export-to-csv";
export default {
    name: "DataReport",
    components: { MonthlyChart },
    data: function () {
        return {
            refreshing: true,
            selectedMonth: null,
            selectedYear: null,
            vouchers: [],
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            years: ["2018", "2019", "2020", "2021", "2022", "2023"],
            chartDatas: [],
            totalVouchers: 0,
            totalPackages: 0,
            totalPrice: 0,
            payment_methods: [
                "လက်ငင်းရှင်း",
                "ဘဏ်လွှဲ",
                "ပစ္စည်းရောက်မှ ရန်ကုန်ရှင်း ",
                "မြို့တွင်းကားခ ရန်ကုန်ရှင်း",
            ],
            yangon_service: [
                "လာယူ",
                "ရန်ကုန်မြို့တွင်း အိမ်အောက် ",
                "နယ်ကားဂိတ်ပို့ ",
                "နယ်အရောက်ပို့",
            ],
        };
    },
    methods: {
        handleExportData: function () {
            const options = {
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: this.selectedMonth + "-" + this.selectedYear + "( Report )",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: this.selectedMonth + "-" + this.selectedYear + "( Report )",
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.getExportData());
        },
        getExportData: function () {
            if (this.vouchers) {
                let export_data = [];
                this.vouchers.map((data) => {
                    export_data.push({
                        Date: data.date,
                        No: data.invoiceId,
                        Sender: data.senderName,
                        Sender_Phone: data.senderPhone,
                        Receiver: data.receiverName,
                        Receiver_Phone: data.receiverPhone,
                        Receiver_Address: data.receiverAddress,
                        Qua: this.getItemsCount(data),
                        Kg: this.getTotalWeight(data),
                        Price: this.getAllPricePerCount(data),
                        CarFee: data.carFeeVal,
                        Total: this.getTotal(data).toFixed(0),
                        Remark:
                            this.getPaymentMethods(data) +
                            "," +
                            this.yangon_service[data.yangonService],
                        Remark1: data.remark1,
                        Remark2: data.remark2,
                        tax: data.tax ?  this.getTax(data).toFixed(0) : "",
                        letterhead: data.letterhead,
                        itemsCn: this.getItemName("cn", data.items),
                        itemsMm: this.getItemName("mm", data.items),
                    });
                });
                return export_data;
            } else {
                return [];
            }
        },
        getItemName: function (lang, items) {
            let itemNames = [];
            if (lang == "cn") {
                items.map((item) => {
                    item.name.map((name) => {
                        itemNames.push(name.nameCn);
                    });
                });
            } else {
                items.map((item) => {
                    item.name.map((name) => {
                        itemNames.push(name.nameMm);
                    });
                });
            }
            return itemNames.join();
        },
        getTax(voucher) {
            return (this.getTotalWithoutTax(voucher) * 5) / 100;
        },
        getTotalWithoutTax(voucher) {
            let items = voucher.items;
            let total = 0;
            items.map((item) => {
                total += this.getPrice(item);
            });
            return total;
        },
        getPaymentMethods: function (voucher) {
            if (typeof voucher.paymentMethod == "object") {
                let methods = "";
                methods += voucher.paymentMethod.map((method) => {
                    return this.payment_methods[method];
                });
                return methods;
            } else {
                return this.payment_methods[voucher.paymentMethod];
            }
        },
        getChartData: function (vouchers) {
            if (vouchers) {
                let chartData = new Array(31).fill(0);
                vouchers.map((data) => {
                    chartData[new Date(data.createdAt).getDate()] += parseInt(this.getTotal(data));
                });
                return chartData;
            } else {
                return [];
            }
        },
        daysInMonth: function (month, year) {
            return new Date(year, month, 0).getDate();
        },
        getDataForTheMonth: async function (monthStr, year) {
            this.refreshing = true;
            let month = this.months.indexOf(monthStr);
            try {
                let voucherCollection = new VoucherCollection();
                let result = await voucherCollection.getDocumentWithCreatedMonth(
                    month,
                    year,
                    this.token
                );
                this.vouchers = result.data;
                let vouchers = result.data;

                this.totalVouchers = this.getTotalVouchers(vouchers);
                this.totalPackages = this.getTotalItems(vouchers);
                this.totalPrice = this.getTotalPrice(vouchers);

                this.chartDatas = this.getChartData(vouchers);
            } catch (error) {
                console.log(error.response);
            }
            this.refreshing = false;
        },
        getItemsCount: function (data) {
            let total_count = 0;
            data.items.map((item) => {
                total_count += parseInt(item.count);
            });
            return total_count;
        },
        getTotal(voucher) {
            let items = voucher.items;
            let total = 0;
            let tax = 5 / 100;

            items.map((item) => {
                total += this.getPrice(item);
            });
            total = parseInt(total.toFixed(0));

            if (voucher.tax) {
                total = total + total * tax + parseFloat(voucher.carFeeVal);
            } else {
                total = total + parseFloat(voucher.carFeeVal);
            }
            total = parseInt(total.toFixed(0));
            return total;
        },
        getPrice(item) {
            let price = parseFloat(item.weight) * parseFloat(item.pricePerCount);
            return price;
        },
        getTotalVouchers: function (vouchers) {
            if (vouchers) {
                return vouchers.length;
            } else {
                return 0;
            }
        },
        getTotalItems: function (vouchers) {
            if (vouchers) {
                let total_items = 0;
                vouchers.map((data) => {
                    data.items.map((item) => {
                        total_items += parseInt(item.count);
                    });
                });
                return total_items;
            } else {
                return 0;
            }
        },
        getTotalPrice: function (vouchers) {
            if (vouchers) {
                let total = 0;
                vouchers.map((data) => {
                    total += parseInt(this.getTotal(data));
                });
                return total;
            } else {
                return 0;
            }
        },
        getTotalWeight: function (voucher) {
            let total = 0;
            voucher.items.map((item) => {
                total += parseFloat(item.weight);
            });
            return total;
        },
        getAllPricePerCount: function (data) {
            let string_to_return = "";
            data.items.map((item, index) => {
                if (index < data.items.length) {
                    string_to_return += item.pricePerCount + ",";
                } else {
                    string_to_return += item.pricePerCount;
                }
            });
            return string_to_return;
        },
    },
    created: function () {
        this.selectedMonth = this.months[new Date().getMonth()];
        this.selectedYear = parseInt(new Date().getFullYear().toString());
        this.getDataForTheMonth(this.selectedMonth, this.selectedYear);
    },
    computed: {
        getYears: function () {
            let startFrom = 2021;
            let currentYear = new Date().getFullYear();
            let years = [];
            do {
                years.push(startFrom);
                startFrom++;
            } while (startFrom <= currentYear);
            return years;
        },
        token: function () {
            return this.$store.state.currentUser.token;
        },
    },
    watch: {
        selectedMonth: function () {
            if (this.chartDatas) {
                this.getDataForTheMonth(this.selectedMonth, this.selectedYear);
            }
        },
        selectedYear: function () {
            if (this.chartDatas) {
                this.getDataForTheMonth(this.selectedMonth, this.selectedYear);
            }
        },
    },
};
</script>
