<template>
    <div class="create-voucher">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-btn
                                depressed
                                rounded
                                class="grey lighten-2"
                                @click="
                                    () => {
                                        $router.go(-1);
                                    }
                                "
                            >
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                CREATE NEW VOUCHER
                            </p>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                rounded
                                class="primary lighten-1"
                                :loading="loading"
                                @click="handleSaveVoucher"
                            >
                                <v-icon>save</v-icon>
                                save voucher
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <h4>Invoice ID & Date</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        prepend-inner-icon="branding_watermark"
                                        label="Invoice Id"
                                        placeholder="Enter Invoice Id"
                                        filled
                                        rounded
                                        x-large
                                        type="text"
                                        v-model="invoiceId"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        prepend-inner-icon="calendar_month"
                                        label="Date"
                                        placeholder="Auto generate avalable"
                                        filled
                                        rounded
                                        x-large
                                        type="text"
                                        v-model="date"
                                    />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <h3 class="subtitle my-10">Sender</h3>
                                            <form>
                                                <v-autocomplete
                                                    dense
                                                    v-model="selectedSender"
                                                    :items="customers"
                                                    :loading="customersLoading"
                                                    :filter="customerFilter"
                                                    class="text-body-2"
                                                    filled
                                                    rounded
                                                    hide-no-data
                                                    item-text="name"
                                                    item-value="API"
                                                    label="Search Customers"
                                                    placeholder="Start typing to search"
                                                    prepend-inner-icon="search"
                                                    return-object
                                                />
                                            </form>
                                            <v-text-field
                                                class="mt-5"
                                                prepend-inner-icon="person"
                                                label="Sender Name"
                                                placeholder="Enter Sender Address"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="senderName"
                                            />
                                            <v-text-field
                                                prepend-inner-icon="map"
                                                label="Sender Address"
                                                placeholder="Enter Sender Address"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="senderAddress"
                                            />
                                            <v-text-field
                                                prepend-inner-icon="phone"
                                                label="Sender Phones"
                                                placeholder="Enter Sender Phones"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="senderPhone"
                                            />
                                            <v-btn
                                                x-small
                                                text
                                                color="primary"
                                                class="text-decoration-underline mb-3"
                                                @click="handleSaveNewSender"
                                                :loading="saveSenderLoading"
                                                >save as new customer</v-btn
                                            >
                                            <v-btn
                                                x-small
                                                text
                                                color="primary"
                                                class="text-decoration-underline mb-3"
                                                @click="handleUpdateNewSender"
                                                :loading="updateSenderLoading"
                                                >update customer</v-btn
                                            >
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <h3 class="subtitle my-10">Receiver</h3>
                                            <form>
                                                <v-autocomplete
                                                    dense
                                                    v-model="selectedReceiver"
                                                    :items="customers"
                                                    :loading="customersLoading"
                                                    :filter="customerFilter"
                                                    class="text-body-2"
                                                    filled
                                                    rounded
                                                    hide-no-data
                                                    item-text="name"
                                                    item-value="API"
                                                    label="Search Customers"
                                                    placeholder="Start typing to search"
                                                    prepend-inner-icon="search"
                                                    return-object
                                                />
                                            </form>
                                            <v-text-field
                                                class="mt-5"
                                                prepend-inner-icon="person"
                                                label="Receiver Name"
                                                placeholder="Enter Sender Name"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="receiverName"
                                            />
                                            <v-text-field
                                                prepend-inner-icon="map"
                                                label="Receiver Address"
                                                placeholder="Enter Sender Address"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="receiverAddress"
                                            />
                                            <v-text-field
                                                prepend-inner-icon="phone"
                                                label="Receiver Phones"
                                                placeholder="Enter Sender Phones"
                                                filled
                                                rounded
                                                x-large
                                                type="text"
                                                v-model="receiverPhone"
                                            />
                                            <v-btn
                                                x-small
                                                text
                                                color="primary"
                                                class="text-decoration-underline mb-3"
                                                @click="handleSaveNewReceiver"
                                                :loading="saveReceiverLoading"
                                                >save as new customer</v-btn
                                            >
                                            <v-btn
                                                x-small
                                                text
                                                color="primary"
                                                class="text-decoration-underline mb-3"
                                                @click="handleUpdateNewReceiver"
                                                :loading="updateReceiverLoading"
                                                >update customer</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <h3 class="subtitle my-10">Payment Methods</h3>
                                    <v-checkbox
                                        v-for="method in paymentMethods"
                                        v-model="paymentMethod"
                                        :label="method.label"
                                        :value="method.value"
                                        :key="method.label"
                                        class="my-0"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <h3 class="subtitle my-10">Yangon Service</h3>
                                    <v-radio-group v-model="yangonService">
                                        <v-radio
                                            v-for="service in yangonServices"
                                            :key="service.label"
                                            :label="service.label"
                                            :value="service.value"
                                            class="my-2"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <h3 class="subtitle my-10">Car Fee</h3>
                                    <v-radio-group v-model="carFee">
                                        <v-radio
                                            v-for="fee in carFees"
                                            :key="fee.label"
                                            :label="fee.label"
                                            :value="fee.value"
                                            class="my-2"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        label="Car Fee Amount"
                                        placeholder="Enter Car Fee Amount"
                                        filled
                                        rounded
                                        x-large
                                        type="text"
                                        dense
                                        v-model="carFeeVal"
                                    />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="subtitle my-10">InClude Tax(5%)</h3>
                                    <v-checkbox
                                        v-model="tax"
                                        label="Add Tax Value(5%)"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <h1 class="primary--text mt-3">{{ getTotal() }} NTD</h1>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th width="200">物品名稱</th>
                                                    <th width="200">貨品總價格(NTD)</th>
                                                    <th width="100">件數</th>
                                                    <th width="100">重量(kg)</th>
                                                    <th width="100">單價(NTD)</th>
                                                    <th width="80">Price(NTD)</th>
                                                    <th width="80">Edit</th>
                                                    <th width="80">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in items" :key="index">
                                                    <td>
                                                        <v-chip
                                                            small
                                                            class="mx-1"
                                                            v-for="name in item.name"
                                                            :key="name.nameCn"
                                                            >{{ name.nameCn }}</v-chip
                                                        >
                                                    </td>
                                                    <td class="number">{{ item.price }}</td>
                                                    <td class="number">{{ item.count }}</td>
                                                    <td class="number">{{ item.weight }}</td>
                                                    <td class="number">
                                                        {{ item.pricePerCount }}
                                                    </td>
                                                    <td class="number">
                                                        {{ getPrice(item).toFixed(0) }}
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            icon
                                                            @click="() => handleEditItem(index)"
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            icon
                                                            @click="() => items.splice(index, 1)"
                                                        >
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                <tr style="margin-top: 30px">
                                                    <td>
                                                        <v-select
                                                            class="mt-3"
                                                            :items="savedItems"
                                                            label="Item Name"
                                                            v-model="itemName"
                                                            dense
                                                            chips
                                                            return-object
                                                            item-text="nameCn"
                                                            multiple
                                                            filled
                                                            rounded
                                                        />
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            class="mt-3"
                                                            dense
                                                            label="Item Price"
                                                            v-model="itemPrice"
                                                            placeholder="Enter Item Price"
                                                            filled
                                                            rounded
                                                            type="text"
                                                        />
                                                    </td>
                                                    <td colspan="2">
                                                        <v-text-field
                                                            class="mt-3"
                                                            dense
                                                            label="Item Count"
                                                            v-model="itemCount"
                                                            placeholder="Enter Item Count"
                                                            filled
                                                            rounded
                                                            type="text"
                                                        />
                                                    </td>
                                                    <td colspan="2">
                                                        <v-text-field
                                                            class="mt-3"
                                                            dense
                                                            label="Weight In Kg"
                                                            v-model="itemWeight"
                                                            placeholder="Enter Weight"
                                                            filled
                                                            rounded
                                                            type="text"
                                                        />
                                                    </td>
                                                    <td colspan="2">
                                                        <v-autocomplete
                                                            dense
                                                            v-model="itemPricePerCount"
                                                            :items="prices"
                                                            :search-input.sync="itemManualText"
                                                            class="text-body-2 mt-3"
                                                            filled
                                                            rounded
                                                            label="Price Per"
                                                            @blur="
                                                                () => {
                                                                    prices.push(
                                                                        parseFloat(itemManualText)
                                                                    );
                                                                    itemPricePerCount =
                                                                        parseFloat(itemManualText);
                                                                }
                                                            "
                                                        >
                                                        </v-autocomplete>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td colspan="2">
                                                        <v-btn
                                                            block
                                                            color="primary"
                                                            @click="() => (itemCreateDialog = true)"
                                                            depressed
                                                            rounded
                                                        >
                                                            <v-icon>bookmark_add</v-icon> Create New
                                                            Item</v-btn
                                                        >
                                                    </td>
                                                    <td colspan="3">
                                                        <v-btn
                                                            block
                                                            color="primary"
                                                            @click="handleAddItem"
                                                            depressed
                                                            rounded
                                                        >
                                                            <v-icon>add</v-icon> Add Item</v-btn
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="subtitle">Notes</h3>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Remark 1"
                                        v-model="remark1"
                                        placeholder="Enter remark1"
                                        filled
                                        rounded
                                        type="text"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Remark 2"
                                        v-model="remark2"
                                        placeholder="Enter remark2"
                                        filled
                                        rounded
                                        type="text"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog width="300" v-model="customerSavedDialog">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <p class="body-2">New Customer Successfully Saved !</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn block text color="primary" @click="() => (customerSavedDialog = false)">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="300" v-model="errorDialog">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <p class="body-2">Please fill all required datas !</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn block text color="primary" @click="() => (errorDialog = false)">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="300" v-model="customerUpdatedDialog">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <p class="body-2">Customer Data Updated !</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        block
                        text
                        color="primary"
                        @click="() => (customerUpdatedDialog = false)"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="itemCreateDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">bookmark_add</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">Create A New Item</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Item Name In Chinese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameCn"
                            />
                            <v-text-field
                                label="Item Name In Burmese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameMm"
                            />
                            <v-text-field
                                label="Item Price"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemPrice"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (itemCreateDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="itemCreateLoading"
                        @click="handleCreateNewItem"
                    >
                        <v-icon>save</v-icon>
                        create new item
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { CustomerCollection } from "@/api/customer_collection";
import { ItemCollection } from "@/api/item_collection";
import { VoucherCollection } from "@/api/voucher_collection";
import { CustomerDocument } from "@/api/customer_document";
export default {
    name: "CreateVoucher",
    data: function () {
        return {
            invoiceId: "",
            date: "",
            senderName: "",
            senderAddress: "",
            senderPhone: "",
            receiverName: "",
            receiverAddress: "",
            receiverPhone: "",
            paymentMethod: [0],
            yangonService: 0,
            carFee: 0,
            carFeeVal: 0,
            items: [],
            tax: false,
            remark1: "",
            remark2: "",
            savedItems: [],
            itemName: "",
            itemPrice: "",
            itemCount: "",
            itemWeight: "",
            itemPricePerCount: "",
            paymentMethods: [
                {
                    label: "現金支付",
                    value: 0,
                },
                {
                    label: "銀行轉帳",
                    value: 1,
                },
                {
                    label: "貨到付款(在仰光付)",
                    value: 2,
                },
                {
                    label: "送府車費(在仰光付)",
                    value: 3,
                },
            ],
            yangonServices: [
                {
                    label: "自取",
                    value: 0,
                },
                {
                    label: "送到府摟下(仰光)",
                    value: 1,
                },
                {
                    label: "送到仰光車站之車費",
                    value: 2,
                },
                {
                    label: "送到府(各城市)",
                    value: 3,
                },
            ],
            carFees: [
                {
                    label: "送到府摟下(仰光)",
                    value: 0,
                },
                {
                    label: "送到仰光車站之車費",
                    value: 1,
                },
                {
                    label: "送到府(各城市)",
                    value: 2,
                },
            ],
            prices: [40, 50, 55, 60, 65, 70, 80, 100, 150, 200, 1200, 1500],
            customers: [],
            customersLoading: false,
            senderSearchText: "",
            customerSearchInDelay: "",
            selectedSender: null,
            selectedReceiver: null,
            receiverSearchText: "",
            loading: false,
            customerSavedDialog: false,
            saveSenderLoading: false,
            saveReceiverLoading: false,
            errorDialog: false,
            newItemNameCn: "",
            newItemNameMm: "",
            newItemPrice: "",
            itemCreateDialog: false,
            itemCreateLoading: false,
            itemManualText: "",
            updateSenderLoading: false,
            updateReceiverLoading: false,
            customerUpdatedDialog: false,
        };
    },
    methods: {
        getTotal: function () {
            let items = this.items;
            let total = 0;
            items.map((item) => {
                total += this.getPrice(item);
            });
            total = parseInt(total.toFixed(0));
            return total;
        },
        getPrice(item) {
            let price = parseFloat(item.weight) * parseFloat(item.pricePerCount);
            return price;
        },
        handleUpdateNewSender: async function () {
            this.updateSenderLoading = true;
            let updateCustomer = {
                name: this.senderName,
                address: this.senderAddress,
                phoneNumber: this.senderPhone,
            };

            try {
                let customer = new CustomerDocument(this.selectedSender._id);
                await customer.updateData(updateCustomer, this.token);
                this.customerUpdatedDialog = true;
            } catch (error) {
                console.log(error.response);
                console.log(error);
            }
            this.updateSenderLoading = false;
        },
        handleUpdateNewReceiver: async function () {
            this.updateReceiverLoading = true;
            let updateCustomer = {
                name: this.receiverName,
                address: this.receiverAddress,
                phoneNumber: this.receiverPhone,
            };

            try {
                let customer = new CustomerDocument(this.selectedReceiver._id);
                let result = await customer.updateData(updateCustomer, this.token);
                console.log(result);
                this.customerUpdatedDialog = true;
            } catch (error) {
                console.log(error.response);
                console.log(error);
            }
            this.updateReceiverLoading = false;
        },
        handleAddItem: function () {
            let newItem = {
                name: this.itemName,
                price: this.itemPrice,
                count: parseFloat(this.itemCount),
                weight: parseFloat(this.itemWeight),
                pricePerCount: parseFloat(this.itemPricePerCount),
            };

            this.items.push(newItem);

            this.itemName = [];
            this.itemPrice = "";
            this.itemCount = "";
            this.itemWeight = "";
            this.itemPricePerCount = "";
        },
        handleEditItem: function (index) {
            this.itemName = this.items[index].name;
            this.itemPrice = this.items[index].price;
            this.itemCount = this.items[index].count;
            this.itemWeight = this.items[index].weight;
            this.itemPricePerCount = this.items[index].pricePerCount;

            this.items.splice(index, 1);
        },
        handleCreateNewItem: async function () {
            this.itemCreateLoading = true;
            let newItem = {
                nameCn: this.newItemNameCn,
                nameMm: this.newItemNameMm,
                price: this.newItemPrice,
            };

            try {
                let itemCollection = new ItemCollection();
                await itemCollection.createDocument(newItem, this.token);

                this.newItemNameCn = "";
                this.newItemNameMm = "";
                this.newItemPrice = "";
                this.itemCreateDialog = false;

                this.loadCreatedItems();
            } catch (error) {
                console.log(error.response);
            }
            this.itemCreateLoading = false;
        },
        loadCreatedItems: async function () {
            try {
                let itemCollection = new ItemCollection();
                let result = await itemCollection.getAllDcouments(this.token);
                this.savedItems = result.data;
            } catch (error) {
                console.log(error.response);
            }
        },
        loadCustomers: async function () {
            try {
                let customerCollection = new CustomerCollection();
                let result = await customerCollection.getAllDcouments(this.token);
                this.customers = result.data;
            } catch (error) {
                console.log(error.response);
            }
        },
        getFormattedTodayDate: function () {
            let date = new Date();
            let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
            let year = date.getFullYear();
            return year + "." + month + "." + day;
        },
        customerFilter(customer, search_text) {
            const name = customer.name.toLowerCase();
            const phone = customer.phoneNumber;
            const query_text = search_text.toLowerCase();

            return name.indexOf(query_text) > -1 || phone.indexOf(query_text) > -1;
        },
        handleSearchSender: async function () {
            this.customersLoading = true;
            try {
                let customerCollection = new CustomerCollection();
                let result = await customerCollection.searchDocuments(
                    this.senderSearchText,
                    this.token
                );
                this.customers = result.data;
            } catch (error) {
                console.log(error.response);
            }
            this.customersLoading = false;
        },
        handleSearchReceiver: async function () {
            this.customersLoading = true;
            try {
                let customerCollection = new CustomerCollection();
                let result = await customerCollection.searchDocuments(
                    this.receiverSearchText,
                    this.token
                );
                this.customers = result.data;
            } catch (error) {
                console.log(error.response);
            }
            this.customersLoading = false;
        },
        handleSaveNewSender: async function () {
            this.saveSenderLoading = true;
            let newCustomer = {
                name: this.senderName,
                address: this.senderAddress,
                phoneNumber: this.senderPhone,
            };

            try {
                let customerCollection = new CustomerCollection();
                await customerCollection.createDocument(newCustomer, this.token);
                this.customerSavedDialog = true;
            } catch (error) {
                console.log(error.response);
            }
            this.saveSenderLoading = false;
        },
        handleSaveNewReceiver: async function () {
            this.saveReceiverLoading = true;
            let newCustomer = {
                name: this.receiverName,
                address: this.receiverAddress,
                phoneNumber: this.receiverPhone,
            };

            try {
                let customerCollection = new CustomerCollection();
                await customerCollection.createDocument(newCustomer, this.token);
                this.customerSavedDialog = true;
            } catch (error) {
                console.log(error.response);
            }
            this.saveReceiverLoading = false;
        },
        handleSaveVoucher: async function () {
            this.loading = true;

            let newVoucher = {
                invoiceId: this.invoiceId,
                date: this.date,
                senderName: this.senderName,
                senderAddress: this.senderAddress,
                senderPhone: this.senderPhone,
                receiverName: this.receiverName,
                receiverAddress: this.receiverAddress,
                receiverPhone: this.receiverPhone,
                paymentMethod: this.paymentMethod,
                yangonService: this.yangonService,
                carFee: this.carFee,
                carFeeVal: parseFloat(this.carFeeVal),
                items: this.items,
                tax: this.tax,
                remark1: this.remark1,
                remark2: this.remark2,
            };

            try {
                let voucherCollection = new VoucherCollection();
                await voucherCollection.createDocument(newVoucher, this.token);
                this.$router.go(-1);
            } catch (error) {
                console.log(error.response);
                this.errorDialog = true;
            }
            this.loading = false;
        },
    },
    created: function () {
        this.loadCreatedItems();
        this.loadCustomers();
        this.date = this.getFormattedTodayDate();
    },
    computed: {
        token: function () {
            return this.$store.state.currentUser.token;
        },
    },
    watch: {
        selectedSender: function () {
            if (this.selectedSender) {
                this.senderName = this.selectedSender.name;
                this.senderAddress = this.selectedSender.address;
                this.senderPhone = this.selectedSender.phoneNumber;
            }
        },
        selectedReceiver: function () {
            if (this.selectedReceiver) {
                this.receiverName = this.selectedReceiver.name;
                this.receiverAddress = this.selectedReceiver.address;
                this.receiverPhone = this.selectedReceiver.phoneNumber;
            }
        },
        itemName: function () {
            let itemPrice = [];
            this.itemName.map((item) => itemPrice.push(item.price));
            this.itemPrice = itemPrice.join();
        },
    },
};
</script>
