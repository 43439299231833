import axios from "axios";
import { Document } from "./document";
import config from "@/config";

class VoucherDocument extends Document {
    root = config.host + "/voucher";

    constructor(documentId) {
        super("voucher", documentId);
    }

    async updateStatus(status, token) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let body = { status };

        let result = await axios.put(`${this.root}/${this.documentId}/update-status`, body, {
            headers,
        });
        return result;
    }

    async updateLetterHead(letterhead, token) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let body = { letterhead };

        let result = await axios.put(`${this.root}/${this.documentId}/update-letterhead`, body, {
            headers,
        });
        return result;
    }
}

export { VoucherDocument };
