import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VuePageTransition } from "vue-page-transition";
import VueHtmlToPaper from "vue-html-to-paper";
import htmlToPaperOptions from "@/helper/html-to-paper-options";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = false;
Vue.use(VueHtmlToPaper, htmlToPaperOptions);

// For page transition with animations
Vue.use(VuePageTransition);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
