<template>
  <div class="app-bar">
    <v-app-bar app class="primary" height="60">
      <NavigationDrawer />
      <p class="font-weight-bold title mt-4 white--text ml-5">
        Gold Of House Co.,Ltd
      </p>
      <v-spacer></v-spacer>
      <p class="mt-4 white--text">
        {{ currentUser.username }} | {{ currentUser.roleLabel }}
      </p>
    </v-app-bar>
  </div>
</template>
<script>
import NavigationDrawer from "@/components/NavigationDrawer.vue";
export default {
  name: "AppBar",
    components: { NavigationDrawer },
  computed: {
    currentUser: function () {
        return this.$store.state.currentUser
    }
  }
};
</script>
