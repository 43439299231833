<template>
    <div class="items-with-list-view">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-icon color="primary">inventory_2</v-icon>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                Items List
                            </p>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                rounded
                                class="primary lighten-1"
                                @click="() => (createDialog = true)"
                            >
                                <v-icon>bookmark_add</v-icon>
                                create new Item
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-data-table
                                :page="page"
                                :pageCount="numberOfPages"
                                :search="search"
                                :loading="loading"
                                :headers="headers"
                                :items="items"
                                :items-per-page="itemsPerPage"
                                :server-items-length="totalItems"
                                :options.sync="options"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:[`item.nameCn`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.nameCn }}</p>
                                </template>
                                <template v-slot:[`item.nameMm`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.nameMm }}</p>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <p class="number text--secondary mt-4">
                                        {{ item.price }}
                                    </p>
                                </template>
                                <template v-slot:[`item.update`]="{ item }">
                                    <v-btn icon @click="() => handleShowUpdateDialog(item)"
                                        ><v-icon>edit</v-icon></v-btn
                                    >
                                </template>
                                <template v-slot:[`item.delete`]="{ item }">
                                    <v-btn
                                        icon
                                        @click="
                                            () => {
                                                deleteDialog = true;
                                                selectedItem = item._id;
                                            }
                                        "
                                        ><v-icon>delete</v-icon></v-btn
                                    >
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="createDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">bookmark_add</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">Create A New Item</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Item Name In Chinese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameCn"
                            />
                            <v-text-field
                                label="Item Name In Burmese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameMm"
                            />
                            <v-text-field
                                label="Item Price"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemPrice"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (createDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="addLoading"
                        @click="handleAddNewItem"
                    >
                        <v-icon>save</v-icon>
                        add new item
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="red">warning</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold red--text">Warning</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    Please confirm that you want to delete this item.This action can't be revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (deleteDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        x-large
                        :loading="deleteLoading"
                        @click="handleDeleteItem"
                    >
                        <v-icon>delete</v-icon>
                        delete item
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="updateDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">edit</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">Edit Saved Item</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Item Name In Chinese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameCn"
                            />
                            <v-text-field
                                label="Item Name In Burmese"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemNameMm"
                            />
                            <v-text-field
                                label="Item Price"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newItemPrice"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (updateDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="updateLoading"
                        @click="handleUpdateSavedItem"
                    >
                        <v-icon>save</v-icon>
                        save edited
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ItemDocument } from "@/api/item_document";
import { ItemCollection } from "@/api/item_collection";
export default {
    name: "ItemsWithListView",
    data: function () {
        return {
            page: 1,
            numberOfPages: null,
            search: null,
            loading: false,
            items: [],
            itemsPerPage: 10,
            totalItems: null,
            options: {},
            headers: [
                { text: "Name In Chinese", value: "nameCn" },
                { text: "Name In Burmese", value: "nameMm" },
                { text: "Item Price", value: "price" },
                { text: "Update", value: "update" },
                { text: "Delete", value: "delete" },
            ],
            createDialog: false,
            newItemNameCn: "",
            newItemNameMm: "",
            newItemPrice: "",
            addLoading: false,
            deleteDialog: false,
            deleteLoading: false,
            selectedItem: null,
            updateDialog: false,
            updateLoading: false,
        };
    },
    methods: {
        readDataWithLimit: async function (page, itemsPerPage) {
            //Table is loading
            this.loading = true;
            let itemCollection = new ItemCollection();
            try {
                var result = await itemCollection.getTotalDocuments(this.token);
                this.totalItems = result.data.count;

                //calculate skip and limit
                var skip = this.totalItems - itemsPerPage * page;
                var limit;
                if (skip < 0) {
                    skip = 0;
                    limit = itemsPerPage + skip;
                } else {
                    limit = itemsPerPage;
                }

                result = await itemCollection.getDocumentWithRange(skip, limit, this.token);
                this.items = result.data.reverse();
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        handleAddNewItem: async function () {
            this.addLoading = true;
            let newItem = {
                nameCn: this.newItemNameCn,
                nameMm: this.newItemNameMm,
                price: parseFloat(this.newItemPrice),
            };

            try {
                let itemCollection = new ItemCollection();
                await itemCollection.createDocument(newItem, this.token);
                this.createDialog = false;

                this.newItemNameCn = "";
                this.newItemNameMm = "";
                this.newItemPrice = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.addLoading = false;
        },
        handleShowUpdateDialog: function (item) {
            this.selectedItem = item._id;
            this.newItemNameCn = item.nameCn;
            this.newItemNameMm = item.nameMm;
            this.newItemPrice = item.price;

            this.updateDialog = true;
        },
        handleUpdateSavedItem: async function () {
            this.updateLoading = true;
            let updatedItem = {
                nameCn: this.newItemNameCn,
                nameMm: this.newItemNameMm,
                price: parseFloat(this.newItemPrice),
            };

            try {
                let itemDocument = new ItemDocument(this.selectedItem);
                await itemDocument.updateData(updatedItem, this.token);
                this.updateDialog = false;

                this.newItemNameCn = "";
                this.newItemNameMm = "";
                this.newItemPrice = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.updateLoading = false;
        },
        handleDeleteItem: async function () {
            this.deleteLoading = true;
            try {
                let itemDocument = new ItemDocument(this.selectedItem);
                await itemDocument.deleteDocument(this.token);

                this.deleteDialog = false;
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error);
            }
            this.deleteLoading = false;
        },
    },
    watch: {
        options: function (options) {
            this.readDataWithLimit(options.page, options.itemsPerPage);
        },
    },
    computed: {
        token: function () {
            return this.$store.state.currentUser.token;
        },
    },
};
</script>
