import axios from "axios";
import config from "../config";

class Auth {
    root = config.host + "/auth";

    async loginAdmin(username, password) {
        let body = {
            username,
            password,
        };

        let result = await axios.post(`${this.root}/login-admin`, body);
        return result;
    }

    async verifyAdmin(token) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.get(`${this.root}/verify-admin`, { headers });
        return result;
    }
}

export { Auth };
