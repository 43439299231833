<template>
    <div class="voucher-detail">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-btn
                                depressed
                                rounded
                                class="grey lighten-2"
                                @click="
                                    () => {
                                        $router.go(-1);
                                    }
                                "
                            >
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                VOUCHER DETAIL
                            </p>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row v-if="loading" style="height: 600px">
                                <v-col cols="12" align="center" class="mt-15">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                    <p class="body-2 mt-10">Plese wait ...</p>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" md="9" class="pa-8">
                                    <div class="pa-10" style="border: 1px dashed #666">
                                        <v-row>
                                            <v-col cols="12" id="to-print">
                                                <div v-if="letterhead == 0">
                                                    <div class="print-main-header-container">
                                                        <h1 class="form_header_letterhead_a">
                                                            {{ formData.cn.letterhead_a_name }}
                                                        </h1>
                                                    </div>
                                                    <div class="print-secondary-header-container">
                                                        <div class="print-logo" />
                                                        <div class="print-secondary-inner">
                                                            <h4 class="form_contact_letterhead_a">
                                                                {{
                                                                    formData.cn
                                                                        .tw_letterhead_a_address
                                                                }}<br />
                                                                {{ formData.cn.tw_letterhead_a_tel
                                                                }}<br />
                                                                {{
                                                                    formData.cn
                                                                        .mm_letterhead_a_address
                                                                }}<br />
                                                                {{
                                                                    formData.cn.mm_letterhead_a_tel
                                                                }}
                                                            </h4>
                                                        </div>
                                                        <div class="print-logo" />
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="print-main-header-container">
                                                        <h1 class="form_header_letterhead_b">
                                                            {{ formData.cn.letterhead_b_name }}
                                                        </h1>
                                                    </div>
                                                    <div class="print-secondary-header-container">
                                                        <div class="print-logo" />
                                                        <div class="print-secondary-inner">
                                                            <h4 class="form_contact_letterhead_b">
                                                                {{ formData.cn.letterhead_b_phone
                                                                }}<br />
                                                                {{ formData.cn.letterhead_b_email
                                                                }}<br />
                                                            </h4>
                                                        </div>
                                                        <div class="print-logo" />
                                                    </div>
                                                </div>
                                                <table class="date-id-container">
                                                    <tr>
                                                        <td>
                                                            {{ formData[currentLang].date }} :
                                                            {{ voucher.date }}
                                                        </td>
                                                        <td>
                                                            {{ formData[currentLang].voucher_id }} :
                                                            {{ voucher.invoiceId }}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table class="main-table">
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            style="width: 50%"
                                                            colspan="2"
                                                            class="border"
                                                        >
                                                            {{ formData[currentLang].sender }} :
                                                            {{ voucher.senderName }}
                                                        </td>
                                                        <td colspan="4" class="border">
                                                            {{ formData[currentLang].sign }} :
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            rowspan="4"
                                                            colspan="2"
                                                            class="border baseline"
                                                        >
                                                            {{ formData[currentLang].address }} :
                                                            {{ voucher.senderAddress }}
                                                            <br />
                                                            <br />
                                                            {{ formData[currentLang].phone }} :
                                                            {{ voucher.senderPhone }}
                                                        </td>
                                                        <td rowspan="4" colspan="2" class="border">
                                                            {{
                                                                formData[currentLang].payment_method
                                                            }}
                                                        </td>
                                                        <td
                                                            class="border"
                                                            align="center"
                                                            v-if="
                                                                typeof voucher.paymentMethod ==
                                                                'object'
                                                            "
                                                        >
                                                            {{
                                                                voucher.paymentMethod.includes(0)
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border" align="center" v-else>
                                                            {{
                                                                voucher.paymentMethod == 0
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td style="width: 25%" class="border">
                                                            {{
                                                                formData[currentLang]
                                                                    .instant_in_cash
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            class="border"
                                                            align="center"
                                                            v-if="
                                                                typeof voucher.paymentMethod ==
                                                                'object'
                                                            "
                                                        >
                                                            {{
                                                                voucher.paymentMethod.includes(1)
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border" align="center" v-else>
                                                            {{
                                                                voucher.paymentMethod == 1
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td style="width: 25%" class="border">
                                                            {{ formData[currentLang].via_bank }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            class="border"
                                                            align="center"
                                                            v-if="
                                                                typeof voucher.paymentMethod ==
                                                                'object'
                                                            "
                                                        >
                                                            {{
                                                                voucher.paymentMethod.includes(2)
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border" align="center" v-else>
                                                            {{
                                                                voucher.paymentMethod == 2
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td style="width: 25%" class="border">
                                                            {{
                                                                formData[currentLang]
                                                                    .cash_on_delivery
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            class="border"
                                                            align="center"
                                                            v-if="
                                                                typeof voucher.paymentMethod ==
                                                                'object'
                                                            "
                                                        >
                                                            {{
                                                                voucher.paymentMethod.includes(3)
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border" align="center" v-else>
                                                            {{
                                                                voucher.paymentMethod == 3
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border">
                                                            {{ formData[currentLang].cash_on_gate }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td colspan="2" class="border">
                                                            {{ formData[currentLang].reciever }} :
                                                            {{ voucher.receiverName }}
                                                        </td>
                                                        <td colspan="4" class="border">
                                                            {{ formData[currentLang].sign }} :
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            rowspan="4"
                                                            colspan="2"
                                                            class="border baseline"
                                                        >
                                                            {{ formData[currentLang].address }} :
                                                            {{ voucher.receiverAddress }}
                                                            <br />
                                                            <br />
                                                            {{ formData[currentLang].phone }} :
                                                            {{ voucher.receiverPhone }}
                                                        </td>
                                                        <td rowspan="4" colspan="2" class="border">
                                                            {{
                                                                formData[currentLang].yangon_service
                                                            }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{
                                                                voucher.yangonService == 0
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border">
                                                            {{ formData[currentLang].collect }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td class="border" align="center">
                                                            {{
                                                                voucher.yangonService == 1
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border">
                                                            {{ formData[currentLang].yangon_dtod }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td class="border" align="center">
                                                            {{
                                                                voucher.yangonService == 2
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border">
                                                            {{ formData[currentLang].other_city }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td class="border" align="center">
                                                            {{
                                                                voucher.yangonService == 3
                                                                    ? "✔"
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td class="border">
                                                            {{
                                                                formData[currentLang]
                                                                    .other_city_dtod
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td
                                                            style="width: 30%"
                                                            class="border"
                                                            align="center"
                                                        >
                                                            {{ formData[currentLang].item_name }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ formData[currentLang].item_value }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ formData[currentLang].count }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ formData[currentLang].weight }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ formData[currentLang].price_per }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ formData[currentLang].price }}
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-for="(item, index) in voucher.items"
                                                        :key="index"
                                                    >
                                                        <td class="border" align="center">
                                                            {{ getItemName(item.name) }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ "" }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ item.count }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ item.weight }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ item.pricePerCount }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ getPrice(item).toFixed(0) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="voucher.tax">
                                                        <td class="border" align="center"></td>
                                                        <td class="border" align="center"></td>
                                                        <td class="border" align="center"></td>
                                                        <td class="border" align="center"></td>
                                                        <td class="border" align="center">5%</td>
                                                        <td class="border" align="center">
                                                            {{ getTax().toFixed(0) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="voucher.carFeeVal !== 0">
                                                        <td colspan="5" class="border" align="left">
                                                            <p
                                                                style="
                                                                    display: inline;
                                                                    margin-left: 30px;
                                                                "
                                                                v-for="(item, index) in formData[
                                                                    currentLang
                                                                ].car_fee"
                                                                :key="index"
                                                            >
                                                                {{
                                                                    voucher.carFee == index
                                                                        ? "( ✔ )"
                                                                        : ""
                                                                }}
                                                                {{ item }}
                                                            </p>
                                                        </td>
                                                        <td class="border" align="center">
                                                            {{ voucher.carFeeVal }}
                                                        </td>
                                                    </tr>
                                                    <!-- row A -->
                                                    <tr>
                                                        <td colspan="3" align="center">
                                                            {{ voucher.remark2 }}
                                                        </td>
                                                        <td
                                                            colspan="2"
                                                            class="border"
                                                            align="right"
                                                        >
                                                            {{ formData[currentLang].total }}
                                                        </td>
                                                        <td class="border" align="center">
                                                            NTD {{ getTotal().toFixed(0) }}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table class="sign-container">
                                                    <tr>
                                                        <td align="right">
                                                            {{
                                                                formData[currentLang].total_sign
                                                            }}________________________{{
                                                                letterhead == 0
                                                                    ? `(${voucher.createdByLabel})`
                                                                    : ""
                                                            }}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <div class="policy-container">
                                                    <h1 class="policy-header" align="center">
                                                        {{ formData[currentLang].rules }}
                                                    </h1>
                                                    <ul>
                                                        <li
                                                            v-for="(rule, index) in formData[
                                                                currentLang
                                                            ].rules_list"
                                                            :key="index"
                                                            :class="{
                                                                margin_left: !rule.important,
                                                            }"
                                                        >
                                                            {{ rule.text }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="12" md="3">
                                    <h4 class="subtitle font-weight-bold primary--text">Status</h4>
                                    <v-radio-group
                                        v-model="voucher.status"
                                        row
                                        @change="handleStatusChange"
                                    >
                                        <v-radio
                                            v-for="radio_item in statues"
                                            :key="radio_item.name"
                                            :label="radio_item.name"
                                            :value="radio_item.value"
                                            class="my-1"
                                            color="primary"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-divider class="my-5"></v-divider>
                                    <h4 class="subtitle font-weight-bold primary--text">
                                        Voucher In
                                    </h4>
                                    <v-radio-group v-model="currentLang" row>
                                        <v-radio
                                            v-for="radio_item in languages"
                                            :key="radio_item.name"
                                            :label="radio_item.name"
                                            :value="radio_item.value"
                                            class="my-1"
                                            color="primary"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-divider class="my-5"></v-divider>
                                    <div v-if="currentRole == 0">
                                        <h4 class="subtitle font-weight-bold primary--text">
                                            Letterhead
                                        </h4>
                                        <v-radio-group
                                            v-model="letterhead"
                                            row
                                            @change="handleLetterHeadChange"
                                        >
                                            <v-radio
                                                v-for="letterhead in letterheads"
                                                :key="letterhead.name"
                                                :label="letterhead.name"
                                                :value="letterhead.value"
                                                class="my-1"
                                                color="primary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <v-divider class="my-5"></v-divider>
                                    </div>
                                    <h4 class="subtitle font-weight-bold primary--text mb-5">
                                        Actions
                                    </h4>
                                    <v-btn
                                        @click="() => handleShare(voucher)"
                                        dark
                                        depressed
                                        large
                                        rounded
                                        block
                                        class="primary my-3"
                                    >
                                        <v-icon>share</v-icon>
                                        share this voucher
                                    </v-btn>
                                    <v-btn
                                        @click="() => handleEditVoucher(voucher)"
                                        dark
                                        depressed
                                        large
                                        rounded
                                        block
                                        class="primary my-3"
                                    >
                                        <v-icon>edit</v-icon>
                                        edit voucher info
                                    </v-btn>
                                    <v-btn
                                        v-if="currentRole == 0"
                                        dark
                                        depressed
                                        large
                                        rounded
                                        block
                                        class="red darken-1 my-3"
                                        :loading="deleteLoading"
                                        @click="() => (deleteDialog = true)"
                                    >
                                        <v-icon>delete</v-icon>
                                        delete voucher
                                    </v-btn>
                                    <v-divider class="my-5"></v-divider>
                                    <h4 class="subtitle font-weight-bold primary--text mb-5">
                                        Print
                                    </h4>
                                    <v-btn
                                        dark
                                        depressed
                                        large
                                        rounded
                                        block
                                        class="green my-3"
                                        @click="printVoucher"
                                    >
                                        <v-icon>print</v-icon>
                                        print
                                    </v-btn>
                                    <v-btn
                                        dark
                                        depressed
                                        large
                                        rounded
                                        block
                                        class="green darken-2 my-3"
                                        @click="printMobile"
                                    >
                                        <v-icon>smartphone</v-icon>
                                        print mobile
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="red">warning</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold red--text">Warning</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    Please confirm that you want to delete this voucher.This action can't be revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (deleteDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        x-large
                        :loading="deleteLoading"
                        @click="handleDeleteVoucher"
                    >
                        <v-icon>delete</v-icon>
                        delete voucher
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import formData from "@/helper/form-data";
import { VoucherDocument } from "@/api/voucher_document";
export default {
    name: "VoucherDetail",
    data: function () {
        return {
            voucherId: this.$route.params.voucherId,
            voucher: null,
            loading: true,
            deleteLoading: false,
            deleteDialog: false,
            formData,
            currentLang: "cn",
            letterhead: 0,
            languages: [
                {
                    name: "Burmese",
                    value: "mm",
                },
                {
                    name: "Chinese",
                    value: "cn",
                },
            ],
            letterheads: [
                {
                    name: "G",
                    value: 0,
                    role: 1,
                },
                {
                    name: "R",
                    value: 1,
                    role: 0,
                },
            ],
            statues: [
                {
                    name: "Shipping",
                    value: 0,
                },
                {
                    name: "Delivered",
                    value: 1,
                },
            ],
        };
    },
    methods: {
        handleLetterHeadChange: async function (val) {
            try {
                let voucherDocument = new VoucherDocument(this.voucherId);
                await voucherDocument.updateLetterHead(val == 0 ? "G" : "R", this.token);
            } catch (error) {
                console.log(error.response);
            }
        },
        handleStatusChange: async function (val) {
            try {
                let voucher = new VoucherDocument(this.voucherId);
                await voucher.updateStatus(val, this.token);
            } catch (error) {
                console.log(error.response);
            }
        },
        printVoucher: function () {
            this.$htmlToPaper("to-print");
        },
        printMobile() {
            // Element to print
            const element = window.document.getElementById("to-print");
            if (!element) {
                alert(`Element to print #${element} not found!`);
                return;
            }
            window.document.write(`
                <html>
                <head>
                    <title>${window.document.title}</title>
                    <link rel="stylesheet" href="/printstyle.css"></link>
                </head>
                <body>
                    ${element.innerHTML}
                </body>
                </html>
            `);
        },
        getTax() {
            return (this.getTotalWithoutTax() * 5) / 100;
        },
        getTotalWithoutTax() {
            let items = this.voucher.items;
            let total = 0;
            items.map((item) => {
                total += this.getPrice(item);
            });
            total = parseInt(total.toFixed(0));
            return total;
        },
        getTotal() {
            let items = this.voucher.items;
            let total = 0;
            let tax = 5 / 100;
            items.map((item) => {
                total += this.getPrice(item);
            });
            total = parseInt(total.toFixed(0));

            if (this.voucher.tax) {
                total = total + total * tax + parseFloat(this.voucher.carFeeVal);
            } else {
                total = total + parseFloat(this.voucher.carFeeVal);
            }
            total = parseInt(total.toFixed(0));
            return total;
        },
        getPrice(item) {
            let price = parseFloat(item.weight) * parseFloat(item.pricePerCount);
            return price;
        },
        getItemName(name_arr) {
            let name_to_return = [];
            if (this.currentLang == "cn") {
                name_arr.map((name) => {
                    name_to_return.push(name.nameCn);
                });
            } else if (this.currentLang == "mm") {
                name_arr.map((name) => {
                    name_to_return.push(name.nameMm);
                });
            }
            return name_to_return.join();
        },
        loadvoucher: async function () {
            this.loading = true;
            try {
                let voucherDocument = new VoucherDocument(this.voucherId);
                let result = await voucherDocument.getDocument(this.token);
                this.voucher = result.data;
                this.status = this.voucher.status;
                this.letterhead = this.voucher.letterhead == "G" ? 0 : 1;
            } catch (error) {
                console.log(error.response);
            }
            this.loading = false;
        },
        handleShare: function (voucher) {
            this.$store.commit("setSelectedVoucher", voucher);
            this.$router.push(
                `/static/voucher-shared/${voucher._id}/${this.currentLang}/${this.letterhead}`
            );
        },
        handleEditVoucher: function (voucher) {
            this.$store.commit("setSelectedVoucher", voucher);
            this.$router.push(`/edit-voucher/${voucher._id}`);
        },
        handleDeleteVoucher: async function () {
            this.deleteLoading = true;
            try {
                let voucher = new VoucherDocument(this.voucher._id);
                await voucher.deleteDocument(this.token);
                this.$router.go(-1);
            } catch (error) {
                console.log(error.response);
            }
            this.deleteLoading = false;
        },
    },
    created: function () {
        if (this.selectedVoucher) {
            this.voucher = this.selectedVoucher;
            this.letterhead = this.voucher.letterhead == "G" ? 0 : 1;
            this.loading = false;
        } else {
            this.loadvoucher();
        }
    },
    computed: {
        selectedVoucher: function () {
            return this.$store.state.selectedVoucher;
        },
        token: function () {
            return this.$store.state.currentUser.token;
        },
        currentRole: function () {
            return this.$store.state.currentUser.role;
        },
    },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Charm&display=swap");
.print-main-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.print-secondary-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.print-secondary-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_header {
    color: black;
    font-family: "Libre Baskeriville";
    font-weight: 600;
    font-size: 26px;
    margin: 0;
}
.form_header_letterhead_a {
    font-family: "roboto";
    font-size: 28px !important;
    font-weight: 600;
    color: black;
}
.form_contact_letterhead_a {
    font-size: 15px;
    color: black;
    margin-top: 10px;
    font-weight: normal;
    line-height: 2rem;
    text-align: center;
    font-family: "roboto";
}
.form_header_letterhead_b {
    font-family: "Charm";
    font-size: 55px !important;
    font-weight: 600;
    color: black;
    margin-top: -20px;
}
.form_contact_letterhead_b {
    font-size: 22px;
    font-weight: 600;
    color: black;
    margin-top: 30px;
    line-height: 2rem;
    text-align: center;
    font-family: "Arial";
}
.form_header_secondary {
    font-family: "Libre Baskeriville";
    font-weight: 600;
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 0;
    color: black;
}
.form_contact {
    font-family: "Libre Baskeriville";
    font-size: 10px;
    margin-top: 6px;
    color: black;
}
.print-logo {
    width: 100px;
    height: 80px;
    margin: 0 -20px;
    margin-top: -10px;
}
.date-id-container {
    width: 100%;
    table-layout: fixed;
    margin: 10px 0px;
}
.date-id-container td {
    font-family: "Libre Baskeriville";
    font-size: small;
    color: black;
}
.main-table {
    width: 100%;
    margin: 10px 0px;
    border-collapse: collapse;
    border: 1px black solid;
}
.main-table td {
    font-family: "Libre Baskeriville";
    font-size: small;
    padding: 4px;
    color: black;
}
.border {
    border: 1px black solid;
}
.sign-container {
    width: 100%;
    table-layout: fixed;
}
.sign-container td {
    font-family: "Libre Baskeriville";
    font-size: x-small;
    padding: 2px;
    color: black;
}
.policy-container {
    margin-top: -10px;
}
.policy-container h1 {
    font-size: medium;
    margin-bottom: 0px;
    color: black;
}
.policy-container ul {
    list-style-type: none;
    padding: 0;
    color: black;
}
.policy-container li {
    font-size: 11px;
    margin: 2px 0px;
    color: black;
}
.margin_left {
    margin-left: 6px !important;
}
</style>
