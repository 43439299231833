<template>
    <div class="share-voucher">
        <div class="voucher-detail">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title> </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" class="pa-8">
                                        <div class="pa-10" style="border: 1px dashed #666">
                                            <v-row v-if="loading" style="height: 600px">
                                                <v-col cols="12" align="center" class="mt-15">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular>
                                                    <p class="body-2 mt-10">Plese wait ...</p>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12" id="to-print">
                                                    <div v-if="letterhead == 0">
                                                        <div class="print-main-header-container">
                                                            <h1 class="form_header_letterhead_a">
                                                                {{ formData.cn.letterhead_a_name }}
                                                            </h1>
                                                        </div>
                                                        <div
                                                            class="print-secondary-header-container"
                                                        >
                                                            <div class="print-logo" />
                                                            <div class="print-secondary-inner">
                                                                <h4
                                                                    class="form_contact_letterhead_a"
                                                                >
                                                                    {{
                                                                        formData.cn
                                                                            .tw_letterhead_a_address
                                                                    }}<br />
                                                                    {{
                                                                        formData.cn
                                                                            .tw_letterhead_a_tel
                                                                    }}<br />
                                                                    {{
                                                                        formData.cn
                                                                            .mm_letterhead_a_address
                                                                    }}<br />
                                                                    {{
                                                                        formData.cn
                                                                            .mm_letterhead_a_tel
                                                                    }}
                                                                </h4>
                                                            </div>
                                                            <div class="print-logo" />
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="print-main-header-container">
                                                            <h1 class="form_header_letterhead_b">
                                                                {{ formData.cn.letterhead_b_name }}
                                                            </h1>
                                                        </div>
                                                        <div
                                                            class="print-secondary-header-container"
                                                        >
                                                            <div class="print-logo" />
                                                            <div class="print-secondary-inner">
                                                                <h4
                                                                    class="form_contact_letterhead_b"
                                                                >
                                                                    {{
                                                                        formData.cn
                                                                            .letterhead_b_phone
                                                                    }}<br />
                                                                    {{
                                                                        formData.cn
                                                                            .letterhead_b_email
                                                                    }}<br />
                                                                </h4>
                                                            </div>
                                                            <div class="print-logo" />
                                                        </div>
                                                    </div>
                                                    <table class="date-id-container">
                                                        <tr>
                                                            <td>
                                                                {{ formData[currentLang].date }} :
                                                                {{ voucher.date }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    formData[currentLang].voucher_id
                                                                }}
                                                                :
                                                                {{ voucher.invoiceId }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table class="main-table">
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                style="width: 50%"
                                                                colspan="2"
                                                                class="border"
                                                            >
                                                                {{ formData[currentLang].sender }} :
                                                                {{ voucher.senderName }}
                                                            </td>
                                                            <td colspan="4" class="border">
                                                                {{ formData[currentLang].sign }} :
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                rowspan="4"
                                                                colspan="2"
                                                                class="border baseline"
                                                            >
                                                                {{ formData[currentLang].address }}
                                                                :
                                                                {{ voucher.senderAddress }}
                                                                <br />
                                                                <br />
                                                                {{ formData[currentLang].phone }} :
                                                                {{ voucher.senderPhone }}
                                                            </td>
                                                            <td
                                                                rowspan="4"
                                                                colspan="2"
                                                                class="border"
                                                            >
                                                                {{
                                                                    formData[currentLang]
                                                                        .payment_method
                                                                }}
                                                            </td>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-if="
                                                                    typeof voucher.paymentMethod ==
                                                                    'object'
                                                                "
                                                            >
                                                                {{
                                                                    voucher.paymentMethod.includes(
                                                                        0
                                                                    )
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-else
                                                            >
                                                                {{
                                                                    voucher.paymentMethod == 0
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td style="width: 25%" class="border">
                                                                {{
                                                                    formData[currentLang]
                                                                        .instant_in_cash
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-if="
                                                                    typeof voucher.paymentMethod ==
                                                                    'object'
                                                                "
                                                            >
                                                                {{
                                                                    voucher.paymentMethod.includes(
                                                                        1
                                                                    )
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-else
                                                            >
                                                                {{
                                                                    voucher.paymentMethod == 1
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td style="width: 25%" class="border">
                                                                {{ formData[currentLang].via_bank }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-if="
                                                                    typeof voucher.paymentMethod ==
                                                                    'object'
                                                                "
                                                            >
                                                                {{
                                                                    voucher.paymentMethod.includes(
                                                                        2
                                                                    )
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-else
                                                            >
                                                                {{
                                                                    voucher.paymentMethod == 2
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td style="width: 25%" class="border">
                                                                {{
                                                                    formData[currentLang]
                                                                        .cash_on_delivery
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-if="
                                                                    typeof voucher.paymentMethod ==
                                                                    'object'
                                                                "
                                                            >
                                                                {{
                                                                    voucher.paymentMethod.includes(
                                                                        3
                                                                    )
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td
                                                                class="border"
                                                                align="center"
                                                                v-else
                                                            >
                                                                {{
                                                                    voucher.paymentMethod == 3
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td class="border">
                                                                {{
                                                                    formData[currentLang]
                                                                        .cash_on_gate
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td colspan="2" class="border">
                                                                {{ formData[currentLang].reciever }}
                                                                :
                                                                {{ voucher.receiverName }}
                                                            </td>
                                                            <td colspan="4" class="border">
                                                                {{ formData[currentLang].sign }} :
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                rowspan="4"
                                                                colspan="2"
                                                                class="border baseline"
                                                            >
                                                                {{ formData[currentLang].address }}
                                                                :
                                                                {{ voucher.receiverAddress }}
                                                                <br />
                                                                <br />
                                                                {{ formData[currentLang].phone }} :
                                                                {{ voucher.receiverPhone }}
                                                            </td>
                                                            <td
                                                                rowspan="4"
                                                                colspan="2"
                                                                class="border"
                                                            >
                                                                {{
                                                                    formData[currentLang]
                                                                        .yangon_service
                                                                }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{
                                                                    voucher.yangonService == 0
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td class="border">
                                                                {{ formData[currentLang].collect }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td class="border" align="center">
                                                                {{
                                                                    voucher.yangonService == 1
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td class="border">
                                                                {{
                                                                    formData[currentLang]
                                                                        .yangon_dtod
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td class="border" align="center">
                                                                {{
                                                                    voucher.yangonService == 2
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td class="border">
                                                                {{
                                                                    formData[currentLang].other_city
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td class="border" align="center">
                                                                {{
                                                                    voucher.yangonService == 3
                                                                        ? "✔"
                                                                        : ""
                                                                }}
                                                            </td>
                                                            <td class="border">
                                                                {{
                                                                    formData[currentLang]
                                                                        .other_city_dtod
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td
                                                                style="width: 30%"
                                                                class="border"
                                                                align="center"
                                                            >
                                                                {{
                                                                    formData[currentLang].item_name
                                                                }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{
                                                                    formData[currentLang].item_value
                                                                }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ formData[currentLang].count }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ formData[currentLang].weight }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{
                                                                    formData[currentLang].price_per
                                                                }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ formData[currentLang].price }}
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            v-for="(item, index) in voucher.items"
                                                            :key="index"
                                                        >
                                                            <td class="border" align="center">
                                                                {{ getItemName(item.name) }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ item.price }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ item.count }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ item.weight }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ item.pricePerCount }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ getPrice(item).toFixed(1) }}
                                                            </td>
                                                        </tr>
                                                        <tr v-if="voucher.tax">
                                                            <td class="border" align="center"></td>
                                                            <td class="border" align="center"></td>
                                                            <td class="border" align="center"></td>
                                                            <td class="border" align="center"></td>
                                                            <td class="border" align="center">
                                                                5%
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ getTax() }}
                                                            </td>
                                                        </tr>
                                                        <tr v-if="voucher.carFeeVal !== 0">
                                                            <td
                                                                colspan="5"
                                                                class="border"
                                                                align="left"
                                                            >
                                                                <p
                                                                    style="
                                                                        display: inline;
                                                                        margin-left: 30px;
                                                                    "
                                                                    v-for="(
                                                                        item, index
                                                                    ) in formData[currentLang]
                                                                        .car_fee"
                                                                    :key="index"
                                                                >
                                                                    {{
                                                                        voucher.carFee == index
                                                                            ? "( ✔ )"
                                                                            : ""
                                                                    }}
                                                                    {{ item }}
                                                                </p>
                                                            </td>
                                                            <td class="border" align="center">
                                                                {{ voucher.carFeeVal }}
                                                            </td>
                                                        </tr>
                                                        <!-- row A -->
                                                        <tr>
                                                            <td colspan="3" align="center">
                                                                {{ voucher.remark2 }}
                                                            </td>
                                                            <td
                                                                colspan="2"
                                                                class="border"
                                                                align="right"
                                                            >
                                                                {{ formData[currentLang].total }}
                                                            </td>
                                                            <td class="border" align="center">
                                                                NTD {{ getTotal().toFixed(1) }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table class="sign-container">
                                                        <tr>
                                                            <td align="right">
                                                                {{
                                                                    formData[currentLang]
                                                                        .total_sign
                                                                }}________________________({{
                                                                    voucher.createdByLabel
                                                                }})
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <div class="policy-container">
                                                        <h1 class="policy-header" align="center">
                                                            {{ formData[currentLang].rules }}
                                                        </h1>
                                                        <ul>
                                                            <li
                                                                v-for="(rule, index) in formData[
                                                                    currentLang
                                                                ].rules_list"
                                                                :key="index"
                                                                :class="{
                                                                    margin_left: !rule.important,
                                                                }"
                                                            >
                                                                {{ rule.text }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import formData from "@/helper/form-data";
import { VoucherDocument } from "@/api/voucher_document";
export default {
    name: "ShareVoucher",
    data: function () {
        return {
            voucherId: this.$route.params.voucherId,
            currentLang: this.$route.params.lang || "mm",
            voucher: null,
            loading: true,
            formData,
            letterhead: this.$route.params.letterhead || 0,
            languages: [
                {
                    name: "Burmese",
                    value: "mm",
                },
                {
                    name: "Chinese",
                    value: "cn",
                },
            ],
            letterheads: [
                {
                    name: "Type A",
                    value: 0,
                },
                {
                    name: "Type B",
                    value: 1,
                },
            ],
        };
    },
    methods: {
        getTax() {
            return (this.getTotalWithoutTax() * 5) / 100;
        },
        getTotalWithoutTax() {
            let items = this.voucher.items;
            let total = 0;
            items.map((item) => {
                total += this.getPrice(item);
            });
            return total;
        },
        getTotal() {
            let items = this.voucher.items;
            let total = 0;
            let tax = 5 / 100;
            items.map((item) => {
                total += this.getPrice(item);
            });

            if (this.voucher.tax) {
                return total + total * tax + parseFloat(this.voucher.carFeeVal);
            } else {
                return total + parseFloat(this.voucher.carFeeVal);
            }
        },
        getPrice(item) {
            return parseFloat(item.weight) * parseFloat(item.pricePerCount);
        },
        getItemName(name_arr) {
            let name_to_return = "";
            if (this.currentLang == "cn") {
                name_arr.map((name) => {
                    name_to_return += name.nameCn;
                });
            } else if (this.currentLang == "mm") {
                name_arr.map((name) => {
                    name_to_return += name.nameMm;
                });
            }
            return name_to_return;
        },
        loadvoucher: async function () {
            this.loading = true;
            try {
                let voucherDocument = new VoucherDocument(this.voucherId);
                let result = await voucherDocument.getDocument();
                this.voucher = result.data;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
            this.loading = false;
        },
    },
    created: function () {
        if (this.selectedVoucher) {
            this.voucher = this.selectedVoucher;
            this.loading = false;
        } else {
            this.loadvoucher();
        }
    },
    computed: {
        selectedVoucher: function () {
            return this.$store.state.selectedVoucher;
        },
    },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Charm&display=swap");
.print-main-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.print-secondary-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.print-secondary-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_header {
    color: black;
    font-family: "Libre Baskeriville";
    font-weight: 600;
    font-size: 26px;
    margin: 0;
}
.form_header_letterhead_a {
    font-family: "roboto";
    font-size: 28px !important;
    font-weight: 600;
    color: black;
}
.form_contact_letterhead_a {
    font-size: 15px;
    color: black;
    margin-top: 10px;
    font-weight: normal;
    line-height: 2rem;
    text-align: center;
    font-family: "roboto";
}
.form_header_letterhead_b {
    font-family: "Charm";
    font-size: 55px !important;
    font-weight: 600;
    color: black;
    margin-top: -20px;
}
.form_contact_letterhead_b {
    font-size: 22px;
    font-weight: 600;
    color: black;
    margin-top: 30px;
    line-height: 2rem;
    text-align: center;
    font-family: "Arial";
}
.form_header_secondary {
    font-family: "Libre Baskeriville";
    font-weight: 600;
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 0;
    color: black;
}
.form_contact {
    font-family: "Libre Baskeriville";
    font-size: 10px;
    margin-top: 6px;
    color: black;
}
.print-logo {
    width: 100px;
    height: 80px;
    margin: 0 -20px;
    margin-top: -10px;
}
.date-id-container {
    width: 100%;
    table-layout: fixed;
    margin: 10px 0px;
}
.date-id-container td {
    font-family: "Libre Baskeriville";
    font-size: small;
    color: black;
}
.main-table {
    width: 100%;
    margin: 10px 0px;
    border-collapse: collapse;
    border: 1px black solid;
}
.main-table td {
    font-family: "Libre Baskeriville";
    font-size: small;
    padding: 4px;
    color: black;
}
.border {
    border: 1px black solid;
}
.sign-container {
    width: 100%;
    table-layout: fixed;
}
.sign-container td {
    font-family: "Libre Baskeriville";
    font-size: x-small;
    padding: 2px;
    color: black;
}
.policy-container {
    margin-top: -10px;
}
.policy-container h1 {
    font-size: medium;
    margin-bottom: 0px;
    color: black;
}
.policy-container ul {
    list-style-type: none;
    padding: 0;
    color: black;
}
.policy-container li {
    font-size: 11px;
    margin: 2px 0px;
    color: black;
}
.margin_left {
    margin-left: 6px !important;
}
</style>
