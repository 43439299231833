<template>
  <div class="login-view">
    <Appbar />
    <router-view />
  </div>
</template>
<script>
import Appbar from "@/components/Appbar.vue";
export default {
  name: "HomeView",
  components: { Appbar },
};
</script>
