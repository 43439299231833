<template>
    <div class="users-with-list-view">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-icon color="primary">admin_panel_settings</v-icon>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                Users List
                            </p>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                rounded
                                class="primary lighten-1"
                                @click="() => (createDialog = true)"
                            >
                                <v-icon>person_add</v-icon>
                                create user
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-data-table
                                :page="page"
                                :pageCount="numberOfPages"
                                :search="search"
                                :loading="loading"
                                :headers="headers"
                                :items="admins"
                                :items-per-page="itemsPerPage"
                                :server-items-length="totalAdmins"
                                :options.sync="options"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:[`item.username`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.username }}</p>
                                </template>
                                <template v-slot:[`item.displayName`]="{ item }">
                                    <p class="text--secondary mt-4">
                                        {{ item.displayName }}
                                    </p>
                                </template>
                                <template v-slot:[`item.role`]="{ item }">
                                    <v-chip small color="primary">{{ item.role == 1 ? "Editor" : "Admin" }}</v-chip>
                                </template>
                                <template v-slot:[`item.update`]="{ item }">
                                    <v-btn icon @click="() => handleShowUpdateDialog(item)"
                                        ><v-icon>edit</v-icon></v-btn
                                    >
                                </template>
                                <template v-slot:[`item.delete`]="{ item }">
                                    <v-btn
                                        icon
                                        @click="
                                            () => {
                                                deleteDialog = true;
                                                selectedItem = item._id;
                                            }
                                        "
                                        ><v-icon>delete</v-icon></v-btn
                                    >
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="createDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">person_add</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">Create A New User</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="New Admin Username"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminUsername"
                            />
                            <v-text-field
                                label="New Admin Displayname"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminDisplayName"
                            />
                            <v-text-field
                                label="New Admin Password"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminPassword"
                            />
                            <v-radio-group row v-model="role">
                                <v-radio :value="0" label="Admin"></v-radio>
                                <v-radio :value="1" label="Editor"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (createDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="addLoading"
                        @click="handleAddNewAdmin"
                    >
                        <v-icon>save</v-icon>
                        add new user
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="updateDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">edit</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">Update Saved User</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="New Admin Username"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminUsername"
                            />
                            <v-text-field
                                label="New Admin Displayname"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminDisplayName"
                            />
                            <v-text-field
                                label="New Admin Password"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newAdminPassword"
                            />
                            <v-radio-group row v-model="role">
                                <v-radio label="Admin" :value="0"></v-radio>
                                <v-radio label="Editor" :value="1"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (updateDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="updateLoading"
                        @click="handleUpdateAdmin"
                    >
                        <v-icon>save</v-icon>
                        update saved user
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="red">warning</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold red--text">Warning</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    Please confirm that you want to delete this user.This action can't be revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (deleteDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        x-large
                        :loading="deleteLoading"
                        @click="handleDeleteAdmin"
                    >
                        <v-icon>delete</v-icon>
                        delete admin
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { AdminDocument } from "@/api/admin-document";
import { AdminCollection } from "@/api/admin_collection";
export default {
    name: "AdminsWithListView",
    data: function () {
        return {
            page: 1,
            numberOfPages: null,
            search: null,
            loading: false,
            admins: [],
            itemsPerPage: 10,
            totalAdmins: null,
            options: {},
            headers: [
                { text: "Username", value: "username" },
                { text: "Displayname", value: "displayName" },
                { text: "Role", value: "role" },
                { text: "Update", value: "update" },
                { text: "Delete", value: "delete" },
            ],
            createDialog: false,
            newAdminUsername: "",
            newAdminDisplayName: "",
            newAdminPassword: "",
            role: 0,
            addLoading: false,
            deleteDialog: false,
            deleteLoading: false,
            selectedItem: null,
            updateDialog: false,
            updateLoading: false,
        };
    },
    methods: {
        readDataWithLimit: async function (page, itemsPerPage) {
            //Table is loading
            let adminCollection = new AdminCollection();
            try {
                var result = await adminCollection.getTotalDocuments(this.token);
                this.totalAdmins = result.data.count;

                //calculate skip and limit
                var skip = this.totalAdmins - itemsPerPage * page;
                var limit;
                if (skip < 0) {
                    skip = 0;
                    limit = itemsPerPage + skip;
                } else {
                    limit = itemsPerPage;
                }

                result = await adminCollection.getDocumentWithRange(skip, limit, this.token);
                this.admins = result.data.reverse();
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        handleAddNewAdmin: async function () {
            this.addLoading = true;
            let newAdmin = {
                username: this.newAdminUsername,
                displayName: this.newAdminDisplayName,
                password: this.newAdminPassword,
                role: this.role,
            };

            try {
                let adminCollection = new AdminCollection();
                await adminCollection.createDocument(newAdmin, this.token);
                this.createDialog = false;

                this.newAdminUsername = "";
                this.newAdminPassword = "";
                this.newAdminDisplayName = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.addLoading = false;
        },
        handleShowUpdateDialog: function (item) {
            this.selectedItem = item._id;
            this.newAdminUsername = item.username;
            this.newAdminDisplayName = item.displayName;
            this.newAdminPassword = item.password;

            this.updateDialog = true;
        },
        handleUpdateAdmin: async function () {
            this.updateLoading = true;
            let updatedAdmin = {
                username: this.newAdminUsername,
                displayName: this.newAdminDisplayName,
                role: this.role,
            };

            try {
                let adminDocument = new AdminDocument(this.selectedItem);
                await adminDocument.updateData(updatedAdmin, this.token);
                this.updateDialog = false;

                this.newAdminUsername = "";
                this.newAdminPassword = "";
                this.newAdminDisplayName = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.updateLoading = false;
        },
        handleDeleteAdmin: async function () {
            this.deleteLoading = true;
            try {
                let adminDocument = new AdminDocument(this.selectedItem);
                await adminDocument.deleteDocument(this.token);

                this.deleteDialog = false;
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error);
            }
            this.deleteLoading = false;
        },
    },
    watch: {
        options: function (options) {
            this.readDataWithLimit(options.page, options.itemsPerPage);
        },
    },
    computed: {
        token: function () {
            return this.$store.state.currentUser.token;
        },
    },
};
</script>
