<template>
    <div class="customers-with-list-view">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-icon color="primary">groups</v-icon>
                            <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                                Customers List
                            </p>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                rounded
                                class="primary lighten-1"
                                @click="() => (createDialog = true)"
                            >
                                <v-icon>person_add</v-icon>
                                create new customer
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-data-table
                                :page="page"
                                :pageCount="numberOfPages"
                                :search="search"
                                :loading="loading"
                                :headers="headers"
                                :items="customers"
                                :items-per-page="itemsPerPage"
                                :server-items-length="totalCustomers"
                                :options.sync="options"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:top>
                                    <form v-on:submit.prevent="handleSearchCustomers">
                                        <v-text-field
                                            color="black"
                                            v-model="searchText"
                                            prepend-icon="search"
                                            placeholder="Search Vouchers"
                                            class="mx-4 black--text text-body-2 px-5"
                                            @click:append="handleSearchCustomers"
                                            clearable
                                            filled
                                            rounded
                                            dense
                                            @click:clear="
                                                readDataWithLimit(
                                                    options.page,
                                                    options.itemsPerPage
                                                )
                                            "
                                        ></v-text-field>
                                    </form>
                                </template>
                                <template v-slot:[`item.name`]="{ item }">
                                    <p class="text--secondary mt-4">{{ item.name }}</p>
                                </template>
                                <template v-slot:[`item.address`]="{ item }">
                                    <p class="number text--secondary mt-4">{{ item.address }}</p>
                                </template>
                                <template v-slot:[`item.phoneNumber`]="{ item }">
                                    <p class="number text--secondary mt-4">
                                        {{ item.phoneNumber }}
                                    </p>
                                </template>
                                <template v-slot:[`item.update`]="{ item }">
                                    <v-btn icon @click="() => handleShowUpdateDialog(item)"
                                        ><v-icon>edit</v-icon></v-btn
                                    >
                                </template>
                                <template v-slot:[`item.delete`]="{ item }">
                                    <v-btn
                                        icon
                                        @click="
                                            () => {
                                                deleteDialog = true;
                                                selectedItem = item._id;
                                            }
                                        "
                                        ><v-icon>delete</v-icon></v-btn
                                    >
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="createDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">person_add</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                        Create A New Customer
                    </p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="New Customer Name"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerName"
                            />
                            <v-text-field
                                label="New Customer Address"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerAddress"
                            />
                            <v-text-field
                                label="New Customer Phone Number"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerPhoneNumber"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (createDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="addLoading"
                        @click="handleAddNewCustomer"
                    >
                        <v-icon>save</v-icon>
                        add new customer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="updateDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="primary">edit</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold primary--text">
                        Update Saved Customer
                    </p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="New Customer Name"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerName"
                            />
                            <v-text-field
                                label="New Customer Address"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerAddress"
                            />
                            <v-text-field
                                class="number"
                                label="New Customer Phone Number"
                                placeholder="Enter value"
                                filled
                                rounded
                                x-large
                                type="text"
                                v-model="newCustomerPhoneNumber"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (updateDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        x-large
                        :loading="updateLoading"
                        @click="handleUpdateCustomer"
                    >
                        <v-icon>save</v-icon>
                        update saved custoemr
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="red">warning</v-icon>
                    <p class="body-1 mt-3 ml-3 font-weight-bold red--text">Warning</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    Please confirm that you want to delete this customer.This action can't be
                    revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="secondary" text x-large @click="() => (deleteDialog = false)">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        x-large
                        :loading="deleteLoading"
                        @click="handleDeleteCustomer"
                    >
                        <v-icon>delete</v-icon>
                        delete customer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { CustomerDocument } from "@/api/customer_document";
import { CustomerCollection } from "@/api/customer_collection";
export default {
    name: "CustomersWithListView",
    data: function () {
        return {
            page: 1,
            numberOfPages: null,
            search: null,
            loading: false,
            customers: [],
            itemsPerPage: 10,
            totalCustomers: null,
            options: {},
            headers: [
                { text: "Customer Name", value: "name" },
                { text: "Customer Address", value: "address" },
                { text: "Phone Numbers", value: "phoneNumber" },
                { text: "Update", value: "update" },
                { text: "Delete", value: "delete" },
            ],
            createDialog: false,
            newCustomerName: "",
            newCustomerAddress: "",
            newCustomerPhoneNumber: "",
            addLoading: false,
            deleteDialog: false,
            deleteLoading: false,
            selectedItem: null,
            updateDialog: false,
            updateLoading: false,
            searchText: "",
        };
    },
    methods: {
        readDataWithLimit: async function (page, itemsPerPage) {
            //Table is loading
            this.loading = true;
            let customerCollection = new CustomerCollection();
            try {
                var result = await customerCollection.getTotalDocuments(this.token);
                this.totalCustomers = result.data.count;

                //calculate skip and limit
                var skip = this.totalCustomers - itemsPerPage * page;
                var limit;
                if (skip < 0) {
                    skip = 0;
                    limit = itemsPerPage + skip;
                } else {
                    limit = itemsPerPage;
                }

                result = await customerCollection.getDocumentWithRange(skip, limit, this.token);
                this.customers = result.data.reverse();
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        handleAddNewCustomer: async function () {
            this.addLoading = true;
            let newCustomer = {
                name: this.newCustomerName,
                address: this.newCustomerAddress,
                phoneNumber: this.newCustomerPhoneNumber,
            };

            try {
                let customerCollection = new CustomerCollection();
                await customerCollection.createDocument(newCustomer, this.token);
                this.createDialog = false;

                this.newCustomerName = "";
                this.newCustomerAddress = "";
                this.newCustomerPhoneNumber = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.addLoading = false;
        },
        handleSearchCustomers: async function () {
            if (this.searchText) {
                this.loading = true;
                try {
                    let customerCollection = new CustomerCollection();
                    let result = await customerCollection.searchDocuments(
                        this.searchText,
                        this.token
                    );
                    this.customers = result.data;
                    this.totalCustomers = result.data.length;
                    this.loading = false;
                } catch (error) {
                    console.log(error.response);
                }
            } else {
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            }
        },
        handleShowUpdateDialog: function (item) {
            this.selectedItem = item._id;
            this.newCustomerName = item.name;
            this.newCustomerAddress = item.address;
            this.newCustomerPhoneNumber = item.phoneNumber;

            this.updateDialog = true;
        },
        handleUpdateCustomer: async function () {
            this.updateLoading = true;
            let updatedCustomer = {
                name: this.newCustomerName,
                address: this.newCustomerAddress,
                phoneNumber: this.newCustomerPhoneNumber,
            };

            try {
                let customerDocument = new CustomerDocument(this.selectedItem);
                await customerDocument.updateData(updatedCustomer, this.token);
                this.updateDialog = false;

                this.newCustomerName = "";
                this.newCustomerAddress = "";
                this.newCustomerPhoneNumber = "";

                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error.response);
            }
            this.updateLoading = false;
        },
        handleDeleteCustomer: async function () {
            this.deleteLoading = true;
            try {
                let customerDocument = new CustomerDocument(this.selectedItem);
                await customerDocument.deleteDocument(this.token);

                this.deleteDialog = false;
                this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
            } catch (error) {
                console.log(error);
            }
            this.deleteLoading = false;
        },
    },
    watch: {
        options: function (options) {
            this.readDataWithLimit(options.page, options.itemsPerPage);
        },
    },
    computed: {
        token: function () {
            return this.$store.state.currentUser.token;
        },
    },
};
</script>
