<template>
  <v-app>
    <v-main class="background">
      <vue-page-transition name="fade">
        <router-view></router-view>
      </vue-page-transition>
    </v-main>
  </v-app>
</template>
<script>
import { VuePageTransition } from 'vue-page-transition';
export default {
  name: "AppView",
  components: {
    VuePageTransition
  }
}
</script>
<style>
.background {
  background-color: #F0F0F0;
}
</style>