<template>
  <div class="login-view">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card class="mt-15">
            <v-card-title class="primary darken-1 py-15">
              <v-row>
                <v-col cols="12" align="center">
                  <h3 class="font-weight-bold title white--text">
                    GOLD OF HOUSE ADMIN LOGIN
                  </h3>
                  <h5 class="font-weight-light white--text">
                    Welcome, please login with your admin account.
                  </h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="py-10">
              <v-text-field
                label="Username"
                placeholder="Enter username"
                filled
                x-large
                type="text"
                v-model="username"
              />
              <v-text-field
                label="Password"
                placeholder="Enter password"
                filled
                x-large
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="password"
              />
              <v-btn
                depressed
                block
                x-large
                class="primary"
                :loading="loading"
                @click="handleLogin"
              >
                login
              </v-btn>
              <v-divider class="my-5"></v-divider>
              <p class="caption">
                &copy;Copyrights {{ new Date().getFullYear() }}, Binary Digital
                Solutions
              </p>
              <v-col cols="12" align="center">
                <p class="caption font-weight-light">version 2.0</p>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- error dialog -->
      <v-dialog v-model="error" width="500">
        <v-card>
          <v-card-title class="text-h5 py-5 red darken-2 white--text">
            <h4 class="title">Failed to login</h4>
          </v-card-title>

          <v-card-text class="my-5">
            Username or password does not match.Please check your username or
            password.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="error = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { Auth } from "@/api/auth";
export default {
  name: "LoginView",
  data: function () {
    return {
      username: "",
      password: "",
      showPassword: false,
      loading: false,
      error: false,
    };
  },
  methods: {
    handleLogin: async function () {
      this.loading = true;
      try {
        let auth = new Auth();
        let result = await auth.loginAdmin(this.username, this.password);
        let user = result.data;

        this.saveCurrentAuth(user);

        this.$router.push("/")
      } catch (error) {
        console.log(error);
        this.error = true;
      }
      this.loading = false;
    },
    saveCurrentAuth: function (user) {
      localStorage.setItem("token", user.token);
      this.$store.commit("setCurrentUser", user);
    },
  },
};
</script>
