const voucher_data = {
    cn: {
        letterhead_a_name: "KO TEE LOGISTICS",
        tw_letterhead_a_address: "TPE: No 2-13, CHUNG XIAO STREET, CHUNG HO",
        tw_letterhead_a_tel: "TEL: 0981 772 578",
        mm_letterhead_a_address: "YGN: NO 71, OU YIN STREET, KYIMYINDAING",
        mm_letterhead_a_tel: "TEL: 09783682046",
        letterhead_b_name: "Crown Sky Co.,Ltd",
        letterhead_b_phone: "Tel : 886-2-86687705",
        letterhead_b_email: "Email:crownsky888@gmail.com",
        date: "日期",
        voucher_id: "寄件編號",
        sender: "寄件公司 / 人",
        sign: "寄件人簽名",
        address: "地址",
        phone: "電話",
        payment_method: "付款方式",
        instant_in_cash: "現金支付",
        via_bank: "銀行轉帳",
        cash_on_delivery: "貨到付款(在仰光付)",
        cash_on_gate: "送府車費(在仰光付)",
        reciever: "收件公司 / 人",
        yangon_service: "當地服務",
        collect: "自取",
        yangon_dtod: "送到府摟下(仰光)",
        other_city: "送到仰光車站之車費",
        other_city_dtod: "送到府(各城市)",
        item_name: "物品名稱",
        item_value: "貨品總價格",
        count: "件數",
        weight: "重量",
        price_per: "單價",
        price: "金額 (NTD)",
        total: "總價",
        total_sign: "簽章",
        rules: "託運須知",
        car_fee: ["送到府摟下(仰光)", "送到仰光車站之車費", "送到府(各城市)"],
        rules_list: [
            {
                important: false,
                text: "(1) 託運人需自行確立托運合法之商品, 如有不符或違反進口相關規定行為時，概與本公司無關，託運人願負一切法律責任",
            },
            {
                important: false,
                text: "(2) 易碎品/易腐爛/需特殊保存之物品，不屬於本公司運輸範圍內，如托運人仍需托運，而造成物品損壞，本公司將不予以賠償。",
            },
            {
                important: false,
                text: "(3) 貨物因包裝不良，導致運送途中損壞，概由托運人自行負責。如造成其他貨物之損害，托運人需負擔損害之貨價。",
            },
            {
                important: false,
                text: "(4) 當貨物遇海關要求予以開箱查驗，我司有權在不告知托運人情況下， 先行予以配合開箱查驗。",
            },
            {
                important: true,
                text: "※(5) 不相同運輸費用之物品，切勿混裝同一包裝內，如經發現，本公司將以最高計價收費處理。",
            },
            {
                important: true,
                text: "※(6) 寄送物品因重量或特殊體積，本公司保有貨物分拆運送之權利。",
            },
            {
                important: true,
                text: "※(7) 托運人虛報不實之物品，導致貨物到貨延遲或查扣，本公司將不予以賠償，且托運人需負一切法律責任。",
            },
            {
                important: false,
                text: "(8) 貨物運送為開船後 1 個月內送達寄送地。如因天候、船班與車輛調動等情事，造成延遲，本公司將另行通知。    ",
            },
            {
                important: false,
                text: "(9) 雨季 6 月~ 9 月易造成貨物受潮或淋溼，請託運人自行做好防水措施，如因包裝不良造成損壞，概由託運人自行負責。    ",
            },
            {
                important: true,
                text: "※(10) 貨物請於通知物品到達後之 5 天內取貨，如超過  5 天後取貨，則另外收取倉儲費。倉儲費以每天/每件緬幣 5000 計算。",
            },
            {
                important: true,
                text: "※(11) 如有不可抗拒之情事，導致貨物遺失，本公司將依國際海商法規，以運輸費用 3 倍賠償。",
            },
        ],
        書籍: "書籍",
        食品類: "食品類",
        衣服: "衣服",
        家用品: "家用品",
        保養品類: "保養品類",
        奶粉: "奶粉 ",
        尿布: "尿布",
        車子相關物品類: "車子相關物品類",
        電子相關物品類: "電子相關物品類",
        電腦相關物品類: "電腦相關物品類",
        體積大輕物品: "體積大輕物品",
        腳踏車: "腳踏車",
        藥品類: "藥品類",
        玩具: "玩具",
        保健食品: "保健食品",
        手錶: "手錶",
        包包: "包包",
        茶葉: "茶葉 ",
        飲料用品: "飲料用品",
        粉類物品: "粉類物品",
        酒: "酒",
        煙: "煙",
        牙籤: "牙籤",
        香水: "香水 ",
        肥皂: "肥皂",
        味精: "味精",
        咖啡: "咖啡",
        洗髮精: "洗髮精",
        沐浴乳: " 沐浴乳",
        手機: "手機",
        印表機和配件: "印表機和配件",
    },
    mm: {
        taiwan_address:
            "Taiwan Address: 2-13,Chung Xiao Rd,Chung Ho Tel: 0981 772 578, 0925 038 565",
        date: "ရက်စွဲ",
        voucher_id: "ဘောင်ချာနံပါတ်",
        sender: "ပို့သူ",
        sign: "လက်မှတ်ထိုးရန်",
        address: "လိပ်စာ",
        phone: "ဖုန်းနံပါတ်",
        payment_method: "ငွေပေးချေမှုနည်းလမ်း",
        instant_in_cash: "လက်ငင်းရှင်း",
        via_bank: "ဘဏ်လွှဲ",
        cash_on_delivery: "ပစ္စည်းရောက်မှ ရန်ကုန်ရှင်း",
        cash_on_gate: "မြို့တွင်းကားခ ရန်ကုန်ရှင်း",
        reciever: "လက်ခံသူ",
        yangon_service: "ရန်ကုန် ၀န်ဆောင်မှု",
        collect: "လာယူ",
        yangon_dtod: "ရန်ကုန်မြို့တွင်း အိမ်အောက်",
        other_city: "နယ်ကားဂိတ်ပို့",
        other_city_dtod: "နယ်အရောက်ပို့",
        item_name: "ပစ္စည်းအမည်",
        item_value: "ပစ္စည်းတန်ဖိုး",
        count: "အရေအတွက်",
        weight: "အလေးချိန်",
        price_per: "နှုန်း",
        price: "ကျသင့်ငွေ",
        total: "စုစုပေါင်းကျသင့်ငွေ",
        total_sign: "လက်မှတ်",
        rules: "ပစ္စည်းပို့သူများလိုက်နာရန်စည်းကမ်းချက်များ",
        car_fee: ["ရန်ကုန်မြို့တွင်းပို့ခ", "နယ်ကားဂိတ်ပို့ခ", "နယ်အရောက်ပို့ခ"],
        rules_list: [
            {
                important: false,
                text: "(၁)  ဥပဒေနှင့်မလွတ်ကင်းသော၊သွင်းကုန်စည်းမျဉ်းများကိုချိုးဖောက်သောပစ္စည်းများပါရှိပါကပို့သူ၏တာဝန်သာဖြစ်ပါသည်။",
            },
            {
                important: false,
                text: "(၂ ) ကွဲရှ၊ယိုစိမ့်၊ကျိုးပဲ့၊ပုပ်သိုးလွယ်သောပစ္စည်းများကြောင့်ဖြစ်ပျက်လာသောပျက်စီးဆုံးရှုံးမှုများကိုတာဝန်မယူပါ။",
            },
            {
                important: false,
                text: "(၃) သေချာစွာထုပ်ပိုးခြင်းမရှိသောပစ္စည်းများ (မိုးတွင်းကာလတွင်စိုထိုင်း，ရေစို) ကြောင့်ဖြစ်ပျက်လာသောပျက်စီးဆုံးရှုံးမှုများကိုတာဝန်မယူပါ။",
            },
            {
                important: false,
                text: "(၄) ကတ်စတန်မှလိုအပ်၍ဖွင့်ကြည့်မည်ဆိုပါကကုမ္ပဏီမှပို့ဆောင်သူအားအကြောင်းမကြားဘဲဖွင့်ပိုင်ခွင့်သဘောတူရမည်။",
            },
            {
                important: true,
                text: "※(၅) ဈေးနှုန်းမတူသောပစ္စည်းများအားရောနှောထည့်ထားခြင်းကိုရှာဖွေတွေ့ရှိပါကဈေးနှုံးမြင့်သောပစ္စည်းပို့ခနှင့်တွက်ပါမည်။ ",
            },
            {
                important: true,
                text: "※(၆) ထုထည်ကြီးမားသောပစ္စည်းများကိုလိုအပ်လျှင်အဆင်ပြေသောအနေအထားရောက်ရှိသည့်အထိဖြုတ်ယူသယ်ဆောင်ခွင့်သဘောတူရမည်။	",
            },
            {
                important: true,
                text: "※(၇) ကုန်ပစ္စည်းစာရင်းအမှန်မပေးဘဲလိမ်လည်ပေးပို့ခဲ့၍ အသိမ်းခံရပါကပို့သူ၏တာဝန်သာဖြစ်သည်။",
            },
            {
                important: false,
                text: "(၈) သင်္ဘောထွက်ပြီး (၁)လ အကြာတွင်ရန်ကုန်သို့ရောက်မည်။ရာသီဥတုဘေးအန္တရယ်များကြောင့်နောက်ကျခဲ့ပါကအကြောင်းကြားပေးမည်။",
            },
            {
                important: false,
                text: "(၉) ကုန်လက်ခံယူသူသည်မိမိပစ္စည်းများလက်ရာပျက်စီးနေပါကတာဝန်ခံရှေ့တွင်ချက်ချင်းစစ်ဆေးပါ။နောက်မှအကြောင်းကြားခြင်းတာဝန်မယူပါ။",
            },
            {
                important: true,
                text: "※(၁ဝ)ပစ္စည်းရောက်ပြီး (၅) ရက်အတွင်းထုတ်ယူရမည်။ရက်ကျော်ပါကထိန်းသိမ်းခကတ်ဖာတဖာကိုတရက်လျှင် ( ၅၀၀၀) ကျပ်နှင့်တွက်ချက်မည်။",
            },
            {
                important: true,
                text: "※(၁၁) ရန်သူမျိုးငါးပါးနှင့်မမျှော်လင့်ဘဲဖြစ်ပေါ်လာသောပျောက်ဆုံးမှုအတွက်ပို့ဆောင်ခ၏（၃）ဆ ပေးလျော်ပါမည်။",
            },
        ],
        書籍: "စာအုပ်",
        食品類: "စားစရာ",
        衣服: "အင်္ကျီ",
        家用品: "အိမ်သုံး အထွေထွေ",
        保養品類: "အလှကုန်",
        奶粉: "နို့မှုန့် ",
        尿布: "Diaper",
        車子相關物品類: "ကားပစ္စည်း",
        電子相關物品類: "လျှပ်စစ်ပစ္စည်း",
        電腦相關物品類: "Computer",
        體積大輕物品: "ထုထည်ပွပစ္စည်း",
        腳踏車: "စက်ဘီး",
        藥品類: "ဆေး",
        玩具: "အရုပ်",
        保健食品: "ကျန်းမာရေးဓါတ်ဆာ",
        手錶: "လတ်ပတ်နာရီ",
        包包: "အိတ်",
        茶葉: "လက်ဖက်ခြောက် ",
        飲料用品: "ဖျော်ရည်နှင့်ဆက်စပ်ပစ္စည်း",
        粉類物品: "အကြော်မှုန့်",
        酒: "အရက်",
        煙: "ဆေးလိပ်",
        牙籤: "သွားကြားထိုးတံ",
        香水: "ရေမွေး ",
        肥皂: "ဆပ်ပြာ",
        味精: "ဟင်းချိုမှုန့်",
        咖啡: "ကော်ဖီထုပ်",
        洗髮精: "ခေါင်းလျှော်ရည်",
        沐浴乳: " ဆပ်ပြာရည်",
        手機: "ဖုန်း ",
        印表機和配件: "ပရင်တာနှင့်ဆက်စပ်ပစ္စည်းများ",
    },
};

export default voucher_data;
