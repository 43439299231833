<template>
    <div class="navigation-drawer">
        <!-- drawer opener icon -->
        <v-app-bar-nav-icon x-large dark @click="drawer = !drawer"> </v-app-bar-nav-icon>
        <!-- main navigation drawer -->
        <v-navigation-drawer v-model="drawer" app light absolute temporary>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item-group mandatory>
                    <!-- navigation item components -->
                    <v-list-item
                        v-for="(menu, index) in menus"
                        :key="index"
                        :to="menu.path"
                        active-class="white primary--text"
                        class="py-4"
                        :disabled="currentRole > menu.role"
                    >
                        <v-list-item-icon v-if="currentRole <= menu.role">
                            <v-icon size="30">{{ menu.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            class="font-weight-bold dark--text"
                            v-if="currentRole <= menu.role"
                            >{{ menu.title }}</v-list-item-title
                        >
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <template v-slot:append>
                <v-list-item class="mb-10">
                    <v-btn text color="error" @click="dialog = true" block>Log Out</v-btn>
                </v-list-item>
            </template>
            <v-row justify="center">
                <v-dialog v-model="dialog" persistent max-width="300">
                    <v-card>
                        <v-card-title class="title"> CONFIRM </v-card-title>
                        <v-card-text> Are you sure to Logout? </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog = false">
                                CANCEL
                            </v-btn>
                            <v-btn color="red darken-1" text @click="logOut"> OK </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    name: "NavigationDrawer",
    data: function () {
        return {
            dialog: false,
            drawer: false,
            menus: [
                {
                    title: "Vouchers In List View",
                    icon: "list",
                    path: "/vouchers-with-list-view/0/10",
                    role: 1,
                },
                {
                    title: "Saved Customers",
                    icon: "groups",
                    path: "/customers-list",
                    role: 1,
                },
                {
                    title: "Saved Items",
                    icon: "inventory_2",
                    path: "/items-list",
                    role: 1,
                },
                {
                    title: "Data Report",
                    icon: "query_stats",
                    path: "/data-report",
                    role: 0,
                },
                {
                    title: "User Roles",
                    icon: "admin_panel_settings",
                    path: "/user-roles",
                    role: 0,
                },
            ],
        };
    },
    methods: {
        logOut: function () {
            localStorage.removeItem("token");
            this.$router.push("/login");
        },
    },
    computed: {
        currentRole: function () {
            return this.$store.state.currentUser.role;
        },
    },
};
</script>
