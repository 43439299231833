import config from "@/config";
import axios from "axios";
import { Collection } from "./collection";

class VoucherCollection extends Collection {
    constructor() {
        super("voucher");
    }

    async deleteWithIds(idsArray, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let body = idsArray;

        let result = await axios.put(
            `${config.host}/${this.document}-collection/delete-with-ids`,
            body,
            { headers }
        );
        return result;
    }
}

export { VoucherCollection };
