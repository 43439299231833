import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        currentUser: null,
        selectedVoucher: null,
    },
    mutations: {
        setCurrentUser: function (state, user) {
            state.currentUser = user;
        },
        setSelectedVoucher: function (state, voucher) {
            state.selectedVoucher = voucher;
        },
    },
});

export default store;
