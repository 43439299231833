import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView";
import HomeView from "@/views/HomeView";
import store from "@/store";
import { Auth } from "@/api/auth";
import VouchersWithListView from "@/pages/VouchersWithListView";
import VoucherDetail from "@/pages/VoucherDetail";
import EditVoucher from "@/pages/EditVoucher";
import CreateVoucher from "@/pages/CreateVoucher";
import CustomersList from "@/pages/CustomersList";
import UserRoles from "@/pages/UserRoles";
import ItemsList from "@/pages/ItemsList";
import DataReport from "@/pages/DataReport";
import ShareVoucher from "@/pages/ShareVoucher";

Vue.use(VueRouter);

const routes = [
    {
        path: "/static/voucher-shared/:voucherId/:lang/:letterhead",
        name: "SharedVoucher",
        component: ShareVoucher,
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "/vouchers-with-list-view/0/0",
        children: [
            {
                path: "/vouchers-with-list-view/:skip/:limit",
                name: "Vouchers With List View",
                component: VouchersWithListView,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/voucher-detail/:voucherId",
                name: "Voucher Detail",
                component: VoucherDetail,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/edit-voucher/:voucherId",
                name: "Edit Voucher",
                component: EditVoucher,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/create-voucher",
                name: "Create Voucher",
                component: CreateVoucher,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/customers-list",
                name: "Customers List",
                component: CustomersList,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/items-list",
                name: "Items List",
                component: ItemsList,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/data-report",
                name: "Data Report",
                component: DataReport,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
            {
                path: "/user-roles",
                name: "UserRoles",
                component: UserRoles,
                beforeEnter: function (to, from, next) {
                    verifyAdminLogin(next);
                },
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
    },
];

const verifyAdminLogin = async function (next) {
    let token;
    if (store.state.currentUser) {
        return next();
    } else {
        token = localStorage.getItem("token");
    }

    if (!token) {
        next("/login");
    } else {
        let auth = new Auth();
        try {
            let response = await auth.verifyAdmin(token);
            let user = response.data;
            user.token = token;
            store.commit("setCurrentUser", user);
            return next();
        } catch (error) {
            console.log(error);
            next("/login");
        }
    }
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
