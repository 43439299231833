import axios from "axios";
import config from "../config";

class Document {
    document;
    documentId;

    constructor(document, documentId) {
        this.document = document;
        this.documentId = documentId;
    }

    async getDocument(token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.get(
            `${config.host}/${this.document}/${this.documentId}/get-data`,
            { headers }
        );
        return result;
    }

    async updateData(updateData, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.put(
            `${config.host}/${this.document}/${this.documentId}/update-data`,
            updateData,
            { headers }
        );
        return result;
    }

    async deleteDocument(token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.delete(`${config.host}/${this.document}/${this.documentId}/delete`, {
            headers,
        });
        return result;
    }
}
export { Document };
