import axios from "axios";
import config from "../config";

class Collection {
    document;

    constructor(document) {
        this.document = document;
    }

    async createDocument(documentObject, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.post(
            `${config.host}/${this.document}-collection` + "/create",
            documentObject,
            { headers }
        );
        return result;
    }

    async createDocuments(documentArray, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.post(
            `${config.host}/${this.document}-collection/create`,
            documentArray,
            { headers }
        );
        return result;
    }

    async getAllDcouments(token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.get(`${config.host}/${this.document}-collection/get-all`, {
            headers,
        });
        return result;
    }

    async getTotalDocuments(token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.get(`${config.host}/${this.document}-collection/total-count`, {
            headers,
        });
        return result;
    }

    async getDocumentWithRange(skip, limit, token = false) {
        let params = {
            skip,
            limit,
        };
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.get(`${config.host}/${this.document}-collection/get-with-range`, {
            headers,
            params,
        });
        return result;
    }

    async searchDocuments(searchText, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = { searchText };
        let result = await axios.get(`${config.host}/${this.document}-collection/search`, {
            headers,
            params,
        });
        return result;
    }

    async getlatestDocuments(count, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = { count };

        let result = await axios.get(`${config.host}/${this.document}-collection/get-latest`, {
            headers,
            params,
        });
        return result;
    }

    async getDocumentWithCreatedDateRange(startDate, endDate, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = {
            startDate,
            endDate,
        };

        let result = await axios.get(
            `${config.host}/${this.document}-collection/get-with-created-date-range`,
            { headers, params }
        );
        return result;
    }

    async getDocumentsWithModifiedDateRange(startDate, endDate, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = {
            startDate,
            endDate,
        };

        let result = await axios.get(
            `${config.host}/${this.document}-collection/get-with-modified-date-range`,
            { headers, params }
        );
        return result;
    }

    async getDocumentWithCreatedDate(date, month, year, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = {
            date,
            month,
            year,
        };

        let result = await axios.get(
            `${config.host}/${this.document}-collection/get-with-created-date`,
            { headers, params }
        );
        return result;
    }

    async getDocumentWithCreatedWeek(date, month, year, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = {
            date,
            month,
            year,
        };

        let result = await axios.get(
            `${config.host}/${this.document}-collection/get-with-created-week`,
            { headers, params }
        );
        return result;
    }

    async getDocumentWithCreatedMonth(month, year, token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;
        let params = {
            month,
            year,
        };

        let result = await axios.get(
            `${config.host}/${this.document}-collection/get-with-created-month`,
            { headers, params }
        );
        return result;
    }

    async deleteTestDocuments(token = false) {
        let headers = {};
        if (token) headers["x-access-token"] = token;

        let result = await axios.delete(`${config.host}/${this.document}-collection/delete-test`, {
            headers,
        });
        return result;
    }
}
export { Collection };
